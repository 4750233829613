import React from 'react';
import { observer } from 'mobx-react-lite';

import OrderCard from '../order-card';
import { ReactComponent as FemaleIcon } from 'src/assets/icons/female.svg';
import { ReactComponent as MaleIcon } from 'src/assets/icons/male.svg';
import { ReactComponent as NoteIcon } from 'src/assets/icons/note.svg';
import { ReactComponent as SimpleHouseIcon } from 'src/assets/icons/simple-house.svg';

import orderStore from 'src/stores/order-store';
import preorderStore from 'src/stores/preorder-store';

import type { DeceasedCardProps } from './deceased-card.props';
import type { IOrderCardRow } from '../order-card';
import { Sex } from 'src/constants';
import type { ICertificate } from 'src/interfaces/certificate';
import { getFormattedDate, getFullName, getYearsLife } from 'src/utils';

const DeceasedCard = observer(({ isPreOrder }: DeceasedCardProps) => {
  const deceased = isPreOrder ? preorderStore.preorder?.deceased : orderStore.order?.deceased;

  if (!deceased) {
    return null;
  }

  let data: IOrderCardRow[] = [];

  if (isPreOrder) {
    const { dob, dod, firstName, lastName, sex } = deceased;
    data = [
      {
        icon: sex === Sex.FEMALE ? <FemaleIcon /> : <MaleIcon />,
        texts: [
          { text: getFullName({ firstName, lastName, patronymic: '' }) },
          { isAdditionalText: true, text: getYearsLife(dob, dod) },
        ],
      },
    ];
  } else {
    const { address, certificate, dob, dod, firstName, lastName, sex } = deceased;

    const { actNo, issuedBy, issueDate, no } = (certificate as ICertificate) ?? {};

    data = [
      {
        icon: sex === Sex.FEMALE ? <FemaleIcon /> : <MaleIcon />,
        texts: [
          { text: getFullName({ firstName, lastName, patronymic: '' }) },
          { isAdditionalText: true, text: getYearsLife(dob, dod) },
        ],
      },
    ];

    if (address) {
      data.push({
        icon: <SimpleHouseIcon />,
        texts: [{ text: address }],
      });
    }

    if (certificate) {
      data.push({
        icon: <NoteIcon />,
        texts: [
          {
            text: `${no ?? ''} от ${getFormattedDate(issueDate)}`,
          },
          { text: `Актовая запись ${actNo ?? ''}` },
          { isAdditionalText: true, text: issuedBy ?? '' },
        ],
      });
    }
  }

  return <OrderCard title="Усопший" data={data} />;
});

export default DeceasedCard;
