export const APP_LOCALSTORAGE_NAME_KEY = 'ha_agreements_web';

export const APP_LOCALSTORAGE_CODE_EXPIRED_KEY = 'code_expired';

export const APP_LOCALSTORAGE_JWT_KEY = 'jwt_token';

export const APP_LOCALSTORAGE_PHONE_KEY = 'phone';

export const APP_LOCALSTORAGE_FIRSTNAME_KEY = 'first_name';

export const APP_LOCALSTORAGE_LASTNAME_KEY = 'last_name';

export const APP_LOCALSTORAGE_PATRONYMIC_KEY = 'patronymic';

export const RESPONSE_HEADERS_TOTAL_COUNT_KEY = 'x-total-count';
