import { makeAutoObservable, reaction } from 'mobx';
import dayjs from 'dayjs';

import globalAppStore from 'src/stores/global-app-store';

import { doPost } from 'src/api';
import { getLocalStorageItem, setLocalStorageItem } from 'src/helpers/localStorageHelper';
import {
  ApiResponseStatus,
  APP_LOCALSTORAGE_CODE_EXPIRED_KEY,
  APP_LOCALSTORAGE_FIRSTNAME_KEY,
  APP_LOCALSTORAGE_LASTNAME_KEY,
  APP_LOCALSTORAGE_PATRONYMIC_KEY,
  APP_LOCALSTORAGE_PHONE_KEY,
} from 'src/constants';
import { LoginStep } from 'src/constants/login-step';

class AuthStore {
  code = '';
  codeTimerId: number | null = null;
  codeTimerRemain = 0;
  isCodeFailed = false;
  isLoading = false;
  isNewCodeRequested = false;
  loginStep = LoginStep.PHONE_INPUT;
  phone = '';

  init() {
    const codeTimerExpired = getLocalStorageItem(APP_LOCALSTORAGE_CODE_EXPIRED_KEY);
    const phone = getLocalStorageItem(APP_LOCALSTORAGE_PHONE_KEY);

    if (!this.isCodeTimerRunning()) {
      setLocalStorageItem(APP_LOCALSTORAGE_CODE_EXPIRED_KEY, '');
    } else {
      this.runCodeTimer();
    }

    if (codeTimerExpired && phone) {
      this.setPhone(phone);
      this.setLoginStep(LoginStep.CODE_INPUT);
    }
  }

  constructor() {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );

    reaction(
      () => this.codeTimerRemain,
      (timer) => timer <= 0 && this.clearCodeTimer()
    );

    this.init();
  }

  setPhone(phone: string) {
    this.phone = phone;
  }

  setCode(code: string) {
    this.code = code;
  }

  setCodeTimeRemain(seconds: number) {
    this.codeTimerRemain = seconds;
  }

  setCodeTimerId(id: any) {
    this.codeTimerId = id;
  }

  setLoginStep(step: LoginStep) {
    this.loginStep = step;
  }

  setIsCodeFailed(isCodeFailed: boolean) {
    this.isCodeFailed = isCodeFailed;
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  setIsNewCodeRequested(isRequested: boolean) {
    this.isNewCodeRequested = isRequested;
  }

  get isPhoneValid() {
    const re = /^\+7 \([0-9]{3}\) [0-9]{3}-[0-9]{2}-[0-9]{2}$/;
    return re.test(this.phone) && !this.isLoading;
  }

  get isCodeValid() {
    const re = /^\d{4}$/;
    return re.test(this.code) && !this.isLoading;
  }

  get isNewCodeRequestDisabled() {
    return this.codeTimerRemain > 0;
  }

  getTimerExpired() {
    const codeTimerExpired = getLocalStorageItem(APP_LOCALSTORAGE_CODE_EXPIRED_KEY);

    return codeTimerExpired ? parseInt(codeTimerExpired, 10) : 0;
  }

  isCodeTimerRunning() {
    const codeTimeExpired = this.getTimerExpired();
    const nowTime = dayjs().unix();

    return codeTimeExpired && codeTimeExpired - nowTime > 0;
  }

  async getVerificationCode() {
    const phone = getLocalStorageItem(APP_LOCALSTORAGE_PHONE_KEY);

    this.setIsNewCodeRequested(false);

    if (phone && phone === this.phone && this.isCodeTimerRunning()) {
      this.runCodeTimer();
      this.setLoginStep(LoginStep.CODE_INPUT);

      return;
    }

    if (phone !== this.phone) {
      setLocalStorageItem(APP_LOCALSTORAGE_PHONE_KEY, this.phone);
    }

    await this.doSendCodeApiRequest();
  }

  async getNewVerificationCode() {
    this.setCode('');
    this.setIsCodeFailed(false);
    this.setIsNewCodeRequested(true);
    await this.doSendCodeApiRequest();
  }

  async doSendCodeApiRequest() {
    this.setIsLoading(true);

    try {
      const options = {
        phone: this.phone.replace(/\D/g, ''),
      };

      const result = await doPost('/signin/phone', options);

      if (result.status === ApiResponseStatus.SUCCESS) {
        const codeTimeExpired = dayjs().add(59, 's').unix().toString();

        setLocalStorageItem(APP_LOCALSTORAGE_CODE_EXPIRED_KEY, codeTimeExpired);

        this.runCodeTimer();
        this.setLoginStep(LoginStep.CODE_INPUT);
      }
    } catch (e) {
      console.log('ERROR: ', e);
    }

    this.setIsLoading(false);
  }

  async checkFormCode() {
    this.setIsLoading(true);

    try {
      const options = {
        phone: this.phone.replace(/\D/g, ''),
        code: this.code,
      };

      const result = await doPost('/signin/auth', options);

      if (result.status === ApiResponseStatus.SUCCESS) {
        const token = result.headers?.authorization?.split('Bearer ')?.[1] || '';
        const { firstname, lastname, patronymic } = result.data;

        setLocalStorageItem(APP_LOCALSTORAGE_FIRSTNAME_KEY, firstname);
        setLocalStorageItem(APP_LOCALSTORAGE_LASTNAME_KEY, lastname);
        setLocalStorageItem(APP_LOCALSTORAGE_PATRONYMIC_KEY, patronymic);
        globalAppStore.setUserName(lastname, firstname, patronymic);

        globalAppStore.setToken(token);

        this.resetPhone();
        this.clearCodeTimer();
      }
    } catch (e) {
      this.setIsCodeFailed(true);
      console.log('error', e);
    }

    this.setIsLoading(false);
  }

  resetPhone() {
    this.setLoginStep(LoginStep.PHONE_INPUT);
    this.setPhone('');
    this.setCode('');
    this.setIsNewCodeRequested(false);
    this.setIsCodeFailed(false);
  }

  runCodeTimer() {
    this.updateCodeTimeRemain();
    this.clear();

    const timerId = setInterval(() => this.updateCodeTimeRemain(), 1000);

    this.setCodeTimerId(timerId);
  }

  clearCodeTimer() {
    this.setCodeTimeRemain(0);
    this.setIsNewCodeRequested(false);

    this.clear();

    setLocalStorageItem(APP_LOCALSTORAGE_CODE_EXPIRED_KEY, '');
  }

  updateCodeTimeRemain() {
    const nowTime = dayjs().unix();
    const timeDiff = this.getTimerExpired() - nowTime;

    if (timeDiff > 0) {
      this.setCodeTimeRemain(timeDiff);
    } else {
      this.clearCodeTimer();
    }
  }

  clear() {
    if (this.codeTimerId) {
      clearInterval(this.codeTimerId);
    }
  }
}

export default new AuthStore();
