import React from 'react';
import classNames from 'classnames';

import ThumbsButton from '../thumbs-button';
import ThumbItem from '../thumb-item';

import type { ThumbsProps } from './thumbs.props';

import './thumbs.scss';

const VISIBLE_THUMBS_AMOUNT = 7;

function Thumbs({ handleImageClick, imageCurrentIndex, images }: ThumbsProps) {
  const thumbRef = React.useRef<HTMLLIElement | null>(null);

  const [thumbsOffset, setThumbsOffset] = React.useState(0);
  const thumbsTransform = `translateX(${thumbsOffset}px)`;
  const thumbsTotalCount = images.length;
  const isButtonDisabled = thumbsTotalCount <= VISIBLE_THUMBS_AMOUNT;

  const handlePrevBtnClick = () => {
    const index = imageCurrentIndex === 0 ? thumbsTotalCount - 1 : imageCurrentIndex - 1;
    handleImageClick(index);
  };

  const handleNextBtnClick = () => {
    const index = imageCurrentIndex === thumbsTotalCount - 1 ? 0 : imageCurrentIndex + 1;
    handleImageClick(index);
  };

  React.useEffect(() => {
    const translateThumbsCarousel = (index: number) => {
      if (thumbsTotalCount <= VISIBLE_THUMBS_AMOUNT) return;

      const thumbWidth = thumbRef.current!.offsetWidth;

      let offset = index - 1;

      if (index === 0) {
        offset = 0;
      }

      if (index > thumbsTotalCount - VISIBLE_THUMBS_AMOUNT + 1) {
        offset = thumbsTotalCount - VISIBLE_THUMBS_AMOUNT;
      }

      if (offset < 0) {
        offset = 0;
      }

      setThumbsOffset(-offset * thumbWidth);
    };

    translateThumbsCarousel(imageCurrentIndex);
  }, [imageCurrentIndex, thumbsTotalCount]);

  return (
    <>
      <div className={classNames('thumbs', isButtonDisabled && 'thumbs_short')}>
        <ThumbsButton
          handleButtonClick={handlePrevBtnClick}
          isButtonDisabled={isButtonDisabled}
          isInitialFocused={!isButtonDisabled}
          modifier="prev"
        />
        <ul className="thumbs__wrapper" style={{ transform: thumbsTransform }}>
          {images.map((image, idx) => (
            <ThumbItem
              className={idx + 1 === thumbsTotalCount ? 'thumb-item_last' : ''}
              key={`thumb-${idx}`}
              handleImageClick={() => handleImageClick(idx)}
              image={image}
              isActive={idx === imageCurrentIndex}
              ref={idx === 0 ? thumbRef : null}
              tabIndex={isButtonDisabled ? 0 : -1}
            />
          ))}
        </ul>
        <ThumbsButton
          handleButtonClick={handleNextBtnClick}
          isButtonDisabled={isButtonDisabled}
          modifier="next"
        />
      </div>

      <ThumbsButton
        className="thumbs-button_short"
        handleButtonClick={handlePrevBtnClick}
        isButtonDisabled={isButtonDisabled}
        modifier="prev"
      />

      <ThumbsButton
        className="thumbs-button_short"
        handleButtonClick={handleNextBtnClick}
        isButtonDisabled={isButtonDisabled}
        modifier="next"
      />
    </>
  );
}

export default Thumbs;
