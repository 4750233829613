import React from 'react';
import { observer } from 'mobx-react-lite';

import Button from 'src/components/ui/button';
import DocumentsList from 'src/components/common/documents-list';
import Loader from 'src/components/common/loader';
import { ReactComponent as UploadIcon } from 'src/assets/icons/upload.svg';

import newAgreementStore from 'src/stores/new-agreement-store';
import popupStore, { POPUP_NAMES } from 'src/stores/popups/popup-store';

import './new-agreement-documents.scss';

const NewAgreementDocuments = observer(() => {
  const handleUploadButtonClick = () => {
    popupStore.showPopup(POPUP_NAMES.UPLOAD_POPUP, {
      onSubmit: newAgreementStore.uploadAgreementDocuments,
    });
  };

  return (
    <div className="new-agreement-documents">
      <div className="new-agreement-documents__header">
        <h2 className="new-agreement-documents__title">Документы</h2>
        <Button
          className="new-agreement-documents__button"
          icon={<UploadIcon width="20" height="20" />}
          flattened
          label="Загрузить"
          onClick={handleUploadButtonClick}
          theme="outlined"
        />
      </div>
      {newAgreementStore.isDocumentsLoading && <Loader className="loader_position_overlay" />}
      <DocumentsList className="new-agreement-documents__list" list={newAgreementStore.documents} />
    </div>
  );
});

export default NewAgreementDocuments;
