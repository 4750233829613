import React from 'react';

import DocumentsList from 'src/components/common/documents-list';

import type { PaymentPageDocumentsProps } from './payment-page-documents.props';

import './payment-page-documents.scss';

const PaymentPageDocuments = ({ documents }: PaymentPageDocumentsProps) => (
  <div className="payment-page-documents">
    <p className="payment-page-documents__title">Приложенные документы</p>
    <DocumentsList className="payment-page-documents__list" list={documents} />
  </div>
);

export default PaymentPageDocuments;
