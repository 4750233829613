import React from 'react';
import { observer } from 'mobx-react-lite';

import OrderTable from '../order-table';

import orderStore from 'src/stores/order-store';
import preorderStore from 'src/stores/preorder-store';
import { getFormattedDate, getFormattedNumber, getFormattedCurrency } from 'src/utils';
import type { ExtraOrderTableProps } from './extra-order-table.props';

const ExtraOrderTable = observer(({ isPreOrder }: ExtraOrderTableProps) => {
  const additionalItems = isPreOrder
    ? preorderStore?.preorder?.items?.additionalItems
    : orderStore?.order?.items?.additionalItems;

  if (!additionalItems?.length) {
    return null;
  }

  let total = 0;

  const data = additionalItems.map((item) => {
    const { sku, name, finishDate, price = 0, amount, unit, discount = 0 } = item;
    const totalRow = price * amount * (1 - discount);
    total += totalRow;

    return {
      listCells: [
        sku,
        name,
        finishDate ? getFormattedDate(finishDate) : '-',
        getFormattedNumber(price),
        discount ? getFormattedNumber(discount * 100) : '-',
        `${amount}`,
        unit,
        getFormattedNumber(totalRow),
      ],
    };
  });

  return (
    <OrderTable
      data={data}
      isExtraTable
      title="Дополнительные товары и услуги"
      total={getFormattedCurrency(total)}
    />
  );
});

export default ExtraOrderTable;
