import React from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';

import Button from 'src/components/ui/button';

import popupStore, { POPUP_NAMES } from 'src/stores/popups/popup-store';

import useOnClickOutside from 'src/hooks/use-on-click-outside';
import useKeyUp from 'src/hooks/use-key-up';
import { Key } from 'src/constants';

import '../popup.scss';

const CONFIRM_ACTION_POPUP = POPUP_NAMES.CONFIRM_ACTION_POPUP;

export const ConfirmPopup = observer(() => {
  const confirmPopupRef = React.useRef<HTMLDivElement | null>(null);

  const {
    isVisible,
    title,
    text,
    cancelButtonLabel = 'Отмена',
    submitButtonLabel,
    onClose,
    onSubmit,
  } = popupStore.getPopupState(CONFIRM_ACTION_POPUP);

  const closePopup = () => {
    popupStore.hidePopup(CONFIRM_ACTION_POPUP);
    onClose?.();
  };

  const handleSubmit = () => {
    closePopup();
    onSubmit?.();
  };

  useOnClickOutside(confirmPopupRef, closePopup, !isVisible);
  useKeyUp(Key.ESCAPE, closePopup, !isVisible);

  return (
    <div
      className={classNames('popup popup_confirm', isVisible && 'popup_visible')}
      ref={confirmPopupRef}
    >
      <div className="popup__title">{title}</div>
      <div className="popup__text">{text}</div>
      <div className="popup__actions">
        <Button onClick={handleSubmit} label={submitButtonLabel} theme="filled" />
        <Button onClick={closePopup} label={cancelButtonLabel} theme="borderless" />
      </div>
    </div>
  );
});

export default ConfirmPopup;
