import React from 'react';
import classNames from 'classnames';

import DocumentItem from 'src/components/common/document-item';

import type { DocumentsListProps } from './documents-list.props';
import type { IDocument } from 'src/interfaces/document.interface';

import './documents-list.scss';

const DocumentsList = ({ className, list }: DocumentsListProps) => {
  return (
    <ul className={classNames('documents-list', className)}>
      {list.map((item: IDocument) => (
        <li className="documents-list__item" key={item.id}>
          <DocumentItem
            className="documents-list__item-wrapper"
            document={item}
            fileName={item.fileName}
          />
        </li>
      ))}
    </ul>
  );
};

export default DocumentsList;
