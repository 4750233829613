import React from 'react';
import { observer } from 'mobx-react-lite';

import constantsStore from 'src/stores/constants-store';

import './order-status-badge.scss';

const OrderStatusBadge = observer(({ status }: { status?: string }) => {
  if (!status) {
    return null;
  }

  return (
    <div className={`order-status-badge order-status-badge_${status}`}>
      {constantsStore.getOrderStatusLabel(status)}
    </div>
  );
});

export default OrderStatusBadge;
