import React from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';

import Header from 'src/components/common/header';
import OrdersTable from './components/orders-table';

import searchOrderStore from 'src/stores/search-order-store';

import './search-orders-page.scss';

const SearchOrdersPage = observer(() => {
  React.useEffect(() => {
    searchOrderStore.init();
  }, []);

  const className = classNames('search-orders-page__content', {
    'search-orders-page__content_empty': !searchOrderStore.orders.length,
  });

  return (
    <div className="search-orders-page">
      <Header extended />
      <main className={className}>
        {searchOrderStore.orders.length ? (
          <>
            <h1 className="search-orders-page__title">Результаты поиска</h1>
            <OrdersTable />
          </>
        ) : (
          <div className="search-orders-page__message">Заказы не найдены</div>
        )}
      </main>
    </div>
  );
});

export default SearchOrdersPage;
