import React from 'react';
import { observer } from 'mobx-react-lite';

import constantsStore from 'src/stores/constants-store';

import type { AgreementAttributeProps } from './agreement-attribute.props';
import { AttributeValue } from 'src/constants';
import { getFormattedDate } from 'src/utils';

const AgreementAttribute = observer(
  ({ attributeType, attributeValue }: AgreementAttributeProps) => {
    const attribute = constantsStore.getAttribute(attributeType);

    const { label = attributeType, value } = attribute ?? {};
    const data =
      value === AttributeValue.DATE_TIME
        ? getFormattedDate(attributeValue as string)
        : attributeValue;

    return (
      <>
        <span className="service-cell__attribute">
          {label}: {data}
        </span>

        <span className="service-cell__divider" />
      </>
    );
  }
);

export default AgreementAttribute;
