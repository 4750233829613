import React from 'react';
import classNames from 'classnames';

import DropboxItem from '../dropbox-item';

import type { DropboxMenuProps } from './dropbox-menu.props';
import { IDropboxItem } from 'src/interfaces/dropbox-item.interface';

import './dropbox-menu.scss';

const DropboxMenu = ({ closeMenu, menuSections, position }: DropboxMenuProps) => {
  return (
    <div className={classNames('dropbox-menu scrollbar', `dropbox-menu_position_${position}`)}>
      <ul className="dropbox-menu__list">
        {menuSections.map(({ list, id }, idx) => (
          <li
            className={classNames(
              'dropbox-menu__list-section',
              idx === menuSections.length - 1 && 'dropbox-menu__list-section_last'
            )}
            key={id}
          >
            <ul className="dropbox-menu__list-nested">
              {list.map((item: IDropboxItem) => (
                <DropboxItem key={item.id} closeMenu={closeMenu} data={item} />
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DropboxMenu;
