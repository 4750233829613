import React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import ServiceCell from 'src/components/common/service-cell';

import agreementStore from 'src/stores/agreement-store';

import { getDiscount, getFormattedCurrency } from 'src/utils';

import './agreement-table.scss';

const TABLE_HEADERS = ['Артикул', 'Услуга', 'Стоимость', 'Скидка', 'Со скидкой'];

const AgreementTable = observer(() => {
  if (!agreementStore.agreement) {
    return null;
  }

  const { discount = 0 } = agreementStore.agreement ?? {};
  const { package: agreementPackage, additionalItems } = agreementStore.agreement?.items ?? {};
  const { calculatedPrice, name, price, sku } = agreementPackage ?? {};

  return (
    <div className="agreement-table">
      <h2 className="agreement-table__title">Товары и услуги в заказе</h2>

      <table className="table agreement-table__table">
        <thead className="agreement-table__table-head">
          <tr className="agreement-table__table-row agreement-table__table-row_head">
            {TABLE_HEADERS.map((item, index) => {
              const headClasses = classNames(
                'agreement-table__table-head-cell',
                index > 1 && 'agreement-table__table-head-cell_right'
              );
              return (
                <th className={headClasses} key={`head-cell${index}`}>
                  {item}
                </th>
              );
            })}
          </tr>
        </thead>

        <tbody className="agreement-table__table-body">
          {agreementPackage ? (
            <>
              <tr className="agreement-table__table-row">
                <td className="agreement-table__table-cell-subtitle" colSpan={TABLE_HEADERS.length}>
                  Заказ
                </td>
              </tr>

              <tr className="agreement-table__table-row agreement-table__table-row_package">
                <td className="agreement-table__table-cell">{sku}</td>

                <td className="agreement-table__table-cell">{name}</td>

                <td className="agreement-table__table-cell agreement-table__table-cell_right">
                  {getFormattedCurrency(price)}
                </td>

                <td className="agreement-table__table-cell agreement-table__table-cell_right">
                  {getDiscount(discount)}
                </td>

                <td className="agreement-table__table-cell agreement-table__table-cell_right">
                  {getFormattedCurrency(calculatedPrice)}
                </td>
              </tr>

              {agreementPackage?.items?.map(({ amount, attributes, name, sku, unit }, index) => {
                const rowClasses = classNames(
                  'agreement-table__table-row',
                  index === 0 && 'agreement-table__table-row_first-item'
                );

                return (
                  <tr className={rowClasses} key={`package-row${index}`}>
                    <td className="agreement-table__table-cell">{sku}</td>

                    <td
                      className="agreement-table__table-cell agreement-table__table-cell_service"
                      colSpan={4}
                    >
                      <ServiceCell
                        amount={amount}
                        attributes={attributes}
                        name={name}
                        unit={unit}
                      />
                    </td>
                  </tr>
                );
              })}
            </>
          ) : null}

          {additionalItems ? (
            <>
              <tr className="agreement-table__table-row">
                <td className="agreement-table__table-cell-subtitle" colSpan={TABLE_HEADERS.length}>
                  Дополнительные товары и услуги
                </td>
              </tr>

              {additionalItems?.map(
                ({ amount, attributes, calculatedPrice, name, price, sku, unit }, index) => (
                  <tr
                    className="agreement-table__table-row agreement-table__table-row_additional"
                    key={`additional-row${index}`}
                  >
                    <td className="agreement-table__table-cell">{sku}</td>

                    <td className="agreement-table__table-cell">
                      <ServiceCell
                        amount={amount}
                        attributes={attributes}
                        name={name}
                        unit={unit}
                      />
                    </td>

                    <td className="agreement-table__table-cell agreement-table__table-cell_right">
                      {getFormattedCurrency(price)}
                    </td>

                    <td className="agreement-table__table-cell agreement-table__table-cell_right">
                      {getDiscount(discount)}
                    </td>

                    <td className="agreement-table__table-cell agreement-table__table-cell_right">
                      {getFormattedCurrency(calculatedPrice)}
                    </td>
                  </tr>
                )
              )}
            </>
          ) : null}
        </tbody>
      </table>
    </div>
  );
});

export default AgreementTable;
