import React from 'react';
import { observer } from 'mobx-react-lite';

import OrderStatusBadge from 'src/components/ui/order-status-badge';

import orderStore from 'src/stores/order-store';

import { getFullName, getFormattedDate, getFormattedTime } from 'src/utils';

import './history-card.scss';

const TABLE_HEADERS = ['Добавлено', 'Статус', 'Пользователь'];

const HistoryCard = observer(() => {
  const orderHistory = orderStore.orderHistory;

  if (!orderHistory?.length) {
    return null;
  }

  return (
    <div className="history-card">
      <h2 className="history-card__title">История</h2>

      <table className="history-card__table table">
        <thead>
          <tr className="history-card__row">
            {TABLE_HEADERS.map((item, index) => (
              <th className="table__head-cell" key={`head-cell${index}`}>
                {item}
              </th>
            ))}
          </tr>
        </thead>

        <tbody className="history-card__body">
          {orderHistory.map(({ status, setAt, user }, index) => {
            return (
              <tr className="history-card__row" key={`row${index}`}>
                <td className="table__cell table__cell_date">
                  <span className="history-card__date">{getFormattedDate(setAt)}</span>

                  <span className="history-card__time">{getFormattedTime(setAt)}</span>
                </td>

                <td className="table__cell">
                  <OrderStatusBadge status={status} />
                </td>

                <td className="table__cell">{getFullName(user)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
});

export default HistoryCard;
