import React from 'react';
import { observer } from 'mobx-react-lite';

import DocumentsList from 'src/components/common/documents-list';

import agreementStore from 'src/stores/agreement-store';

import './agreement-documents.scss';

const AgreementDocuments = observer(() => {
  return (
    <div className="agreement-documents">
      <h2 className="agreement-documents__title">Документы</h2>
      <DocumentsList className="agreement-documents__list" list={agreementStore.documents} />
    </div>
  );
});

export default AgreementDocuments;
