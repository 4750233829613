import React from 'react';
import classNames from 'classnames';

import type { ThumbItemProps } from './thumb-item.props';

import './thumb-item.scss';

const ThumbItem = React.forwardRef<HTMLLIElement, ThumbItemProps>(
  ({ className, image, isActive, handleImageClick, tabIndex }: ThumbItemProps, ref) => {
    const buttonRef = React.useRef<HTMLButtonElement | null>(null);

    React.useEffect(() => {
      if (buttonRef.current && isActive && tabIndex === 0) {
        buttonRef.current.focus();
      }
    }, [isActive, tabIndex]);
    return (
      <li
        className={classNames('thumb-item', isActive && 'thumb-item_active', className)}
        ref={ref}
      >
        <button
          className="thumb-item__button"
          onClick={handleImageClick}
          ref={buttonRef}
          style={{ backgroundImage: `url(${image.thumbUrl}` }}
          tabIndex={tabIndex}
        />
      </li>
    );
  }
);

export default ThumbItem;
