import React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import ButtonIcon from 'src/components/ui/button-icon';
import ImageViewerPhotos from './components/image-viewer-photos';
import { ReactComponent as CloseIcon } from 'src/assets/icons/cross.svg';

import popupStore, { POPUP_NAMES } from 'src/stores/popups/popup-store';

import useKeyUp from 'src/hooks/use-key-up';
import { Key } from 'src/constants';

import './image-viewer.scss';

const ImageViewer = observer(() => {
  const { isVisible } = popupStore.getPopupState(POPUP_NAMES.IMAGE_VIEWER_POPUP);

  useKeyUp(Key.ESCAPE, () => popupStore.hidePopup(POPUP_NAMES.IMAGE_VIEWER_POPUP), !isVisible);

  const handleClick = (event: any) => {
    if (event.nativeEvent?.which && event.nativeEvent?.which !== 1) return;

    if (
      event.target.classList.contains('image-viewer') ||
      event.target.closest('.image-viewer__close-button')
    ) {
      popupStore.hidePopup(POPUP_NAMES.IMAGE_VIEWER_POPUP);
    }
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className={classNames('image-viewer')} onMouseDown={handleClick}>
      <ImageViewerPhotos />

      <ButtonIcon
        ariaLabel="Close"
        className="image-viewer__close-button"
        icon={<CloseIcon className="image-viewer__close-icon" />}
        onClick={handleClick}
      />
    </div>
  );
});

export default ImageViewer;
