import { Route } from 'react-router-dom';

import AgreementPage from 'src/pages/agreement-page';
import LoginPage from 'src/pages/login-page';
import MainPage from 'src/pages/main-page';
import NewAgreementPage from 'src/pages/new-agreement-page';
import OrderPage from 'src/pages/order-page/order-page';
import PaymentPage from 'src/pages/payment-page';
import SearchOrdersPage from 'src/pages/search-orders-page';
import SettingAttributesPage from 'src/pages/setting-attributes-page';

import { AppRoute } from 'src/constants';

const LOG_IN_PAGE_ROUTE = [{ path: AppRoute.LOGIN_PAGE_ROUTE, component: LoginPage }];

const MAIN_ROUTES = [
  { path: AppRoute.MAIN_PAGE_ROUTE, component: MainPage },
  { path: AppRoute.SEARCH_ORDERS_PAGE_ROUTE, component: SearchOrdersPage },
  { path: [AppRoute.ORDER_PAGE_ROUTE, AppRoute.ORDER_PAGE_TAB_ROUTE], component: OrderPage },
  { path: `${AppRoute.AGREEMENT_PAGE_ROUTE}/:id`, component: AgreementPage },
  { path: `${AppRoute.AGREEMENT_PAGE_ROUTE}/:id/payment`, component: PaymentPage },
  { path: `${AppRoute.NEW_AGREEMENT_PAGE_ROUTE}/:id`, component: NewAgreementPage },
  { path: `${AppRoute.SETTING_ATTRIBUTES_PAGE}/:sku`, component: SettingAttributesPage },
];

export function MainPageRoutes() {
  return MAIN_ROUTES.map(({ path, component: Component }) => (
    <Route key={Array.isArray(path) ? path[0] : path} path={path} exact>
      <Component />
    </Route>
  ));
}

export function LogInPageRoutes() {
  return LOG_IN_PAGE_ROUTE.map(({ path, component: Component }) => (
    <Route key={path} path={path} exact>
      <Component />
    </Route>
  ));
}
