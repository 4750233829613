import type { IIdentityApi, IIdentity } from 'src/interfaces/identity';

export const mapApiToIdentity = ({
  issued_by,
  issue_date,
  department_code,
  ...rest
}: IIdentityApi): IIdentity => ({
  ...rest,
  issuedBy: issued_by,
  issueDate: issue_date,
  departmentCode: department_code ? department_code : null,
});
