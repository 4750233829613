import React from 'react';

import { ReactComponent as IconDocFile } from 'src/assets/icons/note.svg';
import { ReactComponent as IconBlankFile } from 'src/assets/icons/note-blank.svg';
import { ReactComponent as ImageFileIcon } from 'src/assets/icons/image-file.svg';

import type { DocumentIconProps } from './document-icon.props';
import { FileIcon, Regex } from 'src/constants';

const parseMimeType = (type: string) => {
  if (Regex.FILE_MIME_IMAGE.test(type)) {
    return FileIcon.IMAGE;
  }

  if (Regex.FILE_MIME_DOCUMENT.test(type)) {
    return FileIcon.DOCUMENT;
  }

  return FileIcon.OTHER;
};

const parseFileExtension = (name: string) => {
  if (Regex.FILE_TYPE_IMAGE.test(name)) {
    return FileIcon.IMAGE;
  }

  if (Regex.FILE_TYPE_DOCUMENT.test(name)) {
    return FileIcon.DOCUMENT;
  }

  return FileIcon.OTHER;
};

const DocumentIcon = ({ className, fileName = '', mimeType }: DocumentIconProps) => {
  const iconType = mimeType ? parseMimeType(mimeType) : parseFileExtension(fileName);

  switch (iconType) {
    case FileIcon.IMAGE:
      return <ImageFileIcon className={className} />;
    case FileIcon.DOCUMENT:
      return <IconDocFile className={className} />;
    default:
      return <IconBlankFile className={className} />;
  }
};

export default DocumentIcon;
