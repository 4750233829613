import React from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';

import AgreementDocuments from './components/agreement-documents';
import AgreementHeader from 'src/components/common/agreement-header';
import AgreementMeta from './components/agreement-meta';
import AgreementTable from './components/agreement-table';

import agreementStore from 'src/stores/agreement-store';

import './agreement-page.scss';

const AgreementPage = observer(() => {
  const params: { id: string } = useParams();
  const agreement = agreementStore.agreement;

  React.useEffect(() => {
    if (params.id) {
      agreementStore.init(params.id);
    }
  }, [params.id]);

  return (
    <div className="agreement-page">
      <AgreementHeader
        title={agreement ? `Доп. соглашение ${agreement?.no} от ${agreement?.createdAt}` : ''}
      >
        <AgreementMeta data={agreement} />
      </AgreementHeader>

      <div className="agreement-page__main scrollbar">
        {agreement && <AgreementTable />}
        {agreementStore.documents.length > 0 && <AgreementDocuments />}
      </div>
    </div>
  );
});

export default AgreementPage;
