import React from 'react';
import { observer } from 'mobx-react-lite';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';

import OrderHeader from './components/order-header';
import InfoTabView from './views/info-tab-view';
import PreorderTabView from './views/preorder-tab-view';
import AgreementTabView from './views/agreement-tab-view';
import DocumentTabView from './views/document-tab-view';

import orderStore from 'src/stores/order-store';

import { OrderTabRoute, AppRoute } from 'src/constants';

import './order-page.scss';

const ORDER_TAB_ROUTES = [
  { path: OrderTabRoute.INFO_TAB, component: InfoTabView },
  { path: OrderTabRoute.PREORDER_TAB, component: PreorderTabView },
  { path: OrderTabRoute.AGREEMENT_TAB, component: AgreementTabView },
  { path: OrderTabRoute.DOCUMENT_TAB, component: DocumentTabView },
];

const OrderPage = observer(() => {
  const params: { id: string } = useParams();

  const no = orderStore.order?.no || '';
  const status = orderStore.order?.status || '';
  const createdAt = orderStore.order?.createdAt || '';

  React.useEffect(() => {
    if (params.id) {
      orderStore.init(params.id);
    }
  }, [params.id]);

  return (
    <div className="order-page">
      <OrderHeader no={no} status={status} createdAt={createdAt} />
      <main className="order-page__main scrollbar">
        <Switch>
          {ORDER_TAB_ROUTES.map(({ path, component: Component }) => (
            <Route key={path} path={path} exact>
              <Component />
            </Route>
          ))}
          <Redirect to={AppRoute.MAIN_PAGE_ROUTE} />
        </Switch>
      </main>
    </div>
  );
});

export default OrderPage;
