import type { ICertificateApi, ICertificate } from 'src/interfaces/certificate';

export const mapApiToCertificate = ({
  issued_by,
  issue_date,
  act_no,
  ...rest
}: ICertificateApi): ICertificate => ({
  ...rest,
  issuedBy: issued_by,
  issueDate: issue_date,
  actNo: act_no,
});
