import React, { SyntheticEvent } from 'react';
import { observer } from 'mobx-react-lite';

import Header from 'src/components/common/header';
import OrderSearchInput from 'src/components/common/order-search-input';
import OrderSearchResultPopup from './components/order-search-result-popup';

import searchOrderStore from 'src/stores/search-order-store';

import { SEARCH_ORDERS_MIN } from 'src/constants';

import './main-page.scss';

const MainPage = observer(() => {
  React.useEffect(() => {
    searchOrderStore.clearSearchString();
    searchOrderStore.init({ limit: SEARCH_ORDERS_MIN });
  }, []);

  const handleSubmit = (e: SyntheticEvent) => e.preventDefault();

  return (
    <div className="main-page">
      <Header />
      <div className="main-page__content">
        <h1 className="main-page__title">Поиск заказа</h1>
        <form autoComplete="off" onSubmit={handleSubmit}>
          <OrderSearchInput autoload />
        </form>
        {searchOrderStore.isVisibleSearchResult && <OrderSearchResultPopup />}
      </div>
    </div>
  );
});

export default MainPage;
