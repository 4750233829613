import { useCallback, useRef, useState } from 'react';

import globalAlertStore from 'src/stores/global-alert-store';

import { ErrorText, FileLimit } from 'src/constants';
import type { IDocumentFile } from 'src/interfaces/document-file.interface';
import { getAlertOptions } from 'src/utils';

export default function useDocumentsUpload() {
  const [files, setFiles] = useState<IDocumentFile[] | []>([]);
  const filesInputRef = useRef<HTMLInputElement | null>(null);

  const handleFilesUpload = useCallback((uploadedFiles: IDocumentFile[] | []) => {
    if (filesInputRef?.current) {
      filesInputRef.current.value = '';
    }
    setFiles(uploadedFiles);
  }, []);

  const handleFilesError = useCallback((receivedError: string) => {
    switch (receivedError) {
      case FileLimit.SIZE:
        globalAlertStore.addAlert(getAlertOptions(ErrorText.FILE_SIZE));
        break;
      case FileLimit.TYPE:
        globalAlertStore.addAlert(getAlertOptions(ErrorText.FILE_TYPE));
        break;
      default:
        break;
    }
  }, []);

  const checkFormFailure = useCallback(() => {
    const isAllDocumentTypes = files.every((file: IDocumentFile) => file.document);

    return files?.length > 0 && !isAllDocumentTypes;
  }, [files]);

  return {
    checkFormFailure,
    files,
    filesInputRef,
    handleFilesUpload,
    handleFilesError,
    isAnyFileLoaded: files?.length > 0,
  };
}
