import React from 'react';
import classNames from 'classnames';

import { EmptyDataProps } from './empty-data.props';

import './empty-data.scss';

function EmptyData({ className, icon, text }: EmptyDataProps) {
  const classes = classNames('empty-data', className);

  return (
    <div className={classes}>
      <span className="empty-data__icon">{icon}</span>

      <p className="empty-data__text">{text}</p>
    </div>
  );
}
export default EmptyData;
