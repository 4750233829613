import React from 'react';
import { observer } from 'mobx-react-lite';

import constantsStore from 'src/stores/constants-store';

import './pre-order-status-badge.scss';

const PreOrderStatusBadge = observer(({ status }: { status?: string }) => {
  if (!status) {
    return null;
  }

  return (
    <div className={`pre-order-status-badge pre-order-status-badge_${status}`}>
      {constantsStore.getPreOrderStatusLabel(status)}
    </div>
  );
});

export default PreOrderStatusBadge;
