import React from 'react';
import { observer } from 'mobx-react-lite';

import OrderCard from 'src/components/common/order-card';
import { ReactComponent as CemeteryIcon } from 'src/assets/icons/cemetery.svg';
import { ReactComponent as ChurchIcon } from 'src/assets/icons/church.svg';

import orderStore from 'src/stores/order-store';

const PlacementCard = observer(() => {
  const { cemetery, farewell } = orderStore.order?.servicePlace || {};

  if (!cemetery && !farewell) {
    return null;
  }

  const data = [];

  if (cemetery) {
    const { name = '', plot = '', section = '' } = cemetery;

    data.push({
      icon: <CemeteryIcon />,
      texts: [
        { text: ` Сектор №${section}, Участок № ${plot}` },
        { isAdditionalText: true, text: name },
      ],
    });
  }

  if (farewell) {
    const { address = '', date = '', name: farewellName = '', time = '' } = farewell;

    data.push({
      icon: <ChurchIcon />,
      texts: [
        { text: farewellName },
        { isAdditionalText: true, text: address },
        { isAdditionalText: true, text: `${date} / ${time}` },
      ],
    });
  }

  return <OrderCard title="Место оказания услуг" className="order-card_placement" data={data} />;
});

export default PlacementCard;
