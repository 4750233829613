export const Unit = {
  CUBIC_METER: 'куб.м.',
  HOUR: 'час',
  KILOGRAM: 'кг.',
  METER: 'метр',
  MIXED: 'Смешанные',
  SQUARE_DECIMETER: 'кв.дм.',
  SQUARE_METER: 'кв.м.',
  THING: 'штука',
};
