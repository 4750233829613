import React from 'react';
import classNames from 'classnames';

import DocumentAttachment from '../document-attachment';
import { ReactComponent as TriangleIcon } from 'src/assets/icons/triangle.svg';

import popupStore, { POPUP_NAMES } from 'src/stores/popups/popup-store';

import type { IAttachment } from 'src/interfaces/attachment.interface';
import type { CommentProps } from './comment.props';
import { Key } from 'src/constants';
import { getFormattedDate, getFormattedTime, getShortFullName, isDocumentFile } from 'src/utils';

import './comment.scss';

function Comment({ message }: CommentProps) {
  const { attachments, comment, createdAt, user } = message;

  let listImages: IAttachment[] = [];
  let listDocuments: IAttachment[] = [];

  if (attachments?.length) {
    attachments.forEach((attachment) => {
      if (isDocumentFile(attachment.mimeType)) {
        listDocuments.push(attachment);
        return;
      }

      listImages.push(attachment);
    });
  }

  const handleImageClick = (index: number) =>
    popupStore.showPopup(POPUP_NAMES.IMAGE_VIEWER_POPUP, {
      images: listImages?.map(({ name, thumbUrl, url }) => ({ name, thumbUrl, url })) ?? [],
      index,
    });

  const handleKeyPress = (e: React.KeyboardEvent, index: number) => {
    if (e.key === Key.ENTER) {
      handleImageClick(index);
    }
  };

  const imageWrapperClasses = classNames(
    'comment__images-wrapper',
    listImages.length > 3 && 'comment__images-wrapper_wide'
  );

  return (
    <div className="comment">
      <span className="comment__user">{getShortFullName(user)}</span>

      <span className="comment__date">
        {getFormattedDate(createdAt)} {getFormattedTime(createdAt)}
      </span>

      <div className="comment__content">
        <TriangleIcon className="comment__triangle-icon" />

        {!!comment ? <p className="comment__message">{comment}</p> : null}

        {!!listImages.length ? (
          <div className={imageWrapperClasses}>
            {listImages.map(({ name, thumbUrl }, index) => (
              <img
                alt={name}
                className="comment__image"
                key={`${thumbUrl}_${index}`}
                onClick={() => handleImageClick(index)}
                onKeyPress={(evt: React.KeyboardEvent) => handleKeyPress(evt, index)}
                src={thumbUrl}
                tabIndex={0}
              />
            ))}
          </div>
        ) : null}

        {!!listDocuments.length ? (
          <div className="comment__documents-wrapper">
            {listDocuments.map(({ name, url }, index) => (
              <DocumentAttachment key={`${url}_${index}`} isDownloaded fileName={name} url={url} />
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Comment;
