import React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import preorderStore from 'src/stores/preorder-store';

import { tableConfig } from './table-config';

import './other-preorders-table.scss';

const OtherPreordersTable = observer(() => {
  const preorders = preorderStore.preorders;

  if (!preorders.length) {
    return null;
  }

  return (
    <div className="other-preorders-table">
      <h2 className="other-preorders-table__title">Другие предзаказы с этим усопшим</h2>

      <table className="other-preorders-table__table">
        <thead>
          <tr className="other-preorders-table__row">
            {tableConfig.map(({ label, field }) => (
              <th className="table__head-cell" key={`head-cell_${field}`}>
                {label}
              </th>
            ))}
          </tr>
        </thead>

        <tbody className="other-preorders-table__body">
          {preorders.map((preorder, idx) => (
            <tr
              className="other-preorders-table__row other-preorders-table__row_bordered"
              key={`row-${idx}`}
            >
              {tableConfig.map(({ field, className, renderFn = (val) => val }) => (
                <td
                  key={`body-cell_${field}`}
                  className={classNames(
                    'other-preorders-table__table-cell',
                    `other-preorders-table__table-cell_${className}`
                  )}
                >
                  {renderFn(preorder[field])}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
});

export default OtherPreordersTable;
