import classNames from 'classnames';

import type { IString } from 'src/interfaces/string.interface';

type IFunc = (value: string) => (str: string, index: number) => IString;

export function getMask(func: IFunc) {
  return function (value: string) {
    let listStrings: IString[] = [];

    value
      .split('')
      .map(func(value))
      .forEach((item) => {
        const length = listStrings.length;
        const lastIndex = length - 1;

        if (length === 0) {
          listStrings.push(item);
          return;
        }

        if (listStrings[lastIndex].isTextBlack === item.isTextBlack) {
          listStrings[lastIndex].str = listStrings[lastIndex].str + item.str;
        } else {
          listStrings.push(item);
        }
      });

    return listStrings.map(({ str, isTextBlack }, index) => {
      const classes = classNames('input__mask-text', isTextBlack && 'input__mask-text_black');

      return (
        <span className={classes} key={index}>
          {str}
        </span>
      );
    });
  };
}
