import type { IOrderHistoryApi, IOrderHistory } from 'src/interfaces/order-history.interface';

export const mapApiToOrderHistory = ({
  set_at,
  user,
  ...rest
}: IOrderHistoryApi): IOrderHistory => ({
  ...rest,
  setAt: set_at,
  user: {
    firstName: user.firstname,
    lastName: user.lastname,
    patronymic: user.patronymic,
  },
});
