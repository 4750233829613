import React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import authStore from 'src/stores/auth-store';

import './message-field.scss';

interface IMessage {
  isError?: boolean;
  title: string;
  subTitle?: string;
}

const MESSAGES = {
  CODE_SENT: 'Код отправлен на номер ',
  NEW_CODE_SENT: 'Новый код отправлен на номер ',
  WRONG_CODE_ENTERED: 'Неправильно введен код, попробуйте еще раз',
  YOU_CAN_REQUEST_CODE: 'Вы можете запросить код повторно через ',
};

const MessageField = observer(() => {
  const getMessages = () => {
    if (authStore.isCodeFailed && authStore.codeTimerRemain) {
      return [
        { isError: true, title: MESSAGES.WRONG_CODE_ENTERED },
        {
          isError: true,
          title: MESSAGES.YOU_CAN_REQUEST_CODE,
          subTitle: `${authStore.codeTimerRemain} сек.`,
        },
      ];
    }

    if (authStore.isCodeFailed) {
      return [{ isError: true, title: MESSAGES.WRONG_CODE_ENTERED }];
    }

    if (authStore.isNewCodeRequested) {
      return [
        { title: MESSAGES.NEW_CODE_SENT, subTitle: authStore.phone },
        {
          title: MESSAGES.YOU_CAN_REQUEST_CODE,
          subTitle: `${authStore.codeTimerRemain} сек.`,
        },
      ];
    }

    if (authStore.codeTimerRemain) {
      return [
        { title: MESSAGES.CODE_SENT, subTitle: authStore.phone },
        {
          title: MESSAGES.YOU_CAN_REQUEST_CODE,
          subTitle: `${authStore.codeTimerRemain} сек.`,
        },
      ];
    }

    return [{ title: MESSAGES.CODE_SENT, subTitle: authStore.phone }];
  };

  const renderMessages = (messages: IMessage[]) =>
    messages.map(({ isError, title, subTitle }) => {
      const classes = classNames('message-field__text', isError && 'message-field__text_error');

      const subTitleClasses = classNames(
        'message-field__text message-field__text_medium',
        isError && 'message-field__text_error'
      );

      return (
        <div className={classes} key={title}>
          {title}
          {subTitle ? <span className={subTitleClasses}>{subTitle}</span> : null}
        </div>
      );
    });

  return <div className="message-field">{renderMessages(getMessages())}</div>;
});

export default MessageField;
