import type { IComment, ICommentApi } from 'src/interfaces/comment.interface';

export const mapApiToComment = ({
  created_at,
  user,
  attachments,
  ...rest
}: ICommentApi): IComment => ({
  ...rest,
  createdAt: created_at,
  user: {
    lastName: user?.lastname ?? '',
    firstName: user?.firstname ?? '',
    patronymic: user?.patronymic ?? '',
  },
  attachments: attachments
    ? attachments.map(({ mime_type, created_at, thumb_url, ...restAttachment }) => ({
        ...restAttachment,
        mimeType: mime_type,
        createdAt: created_at,
        thumbUrl: thumb_url,
      }))
    : null,
});
