import { v4 as uuidv4 } from 'uuid';

import type { IDocumentApi } from 'src/interfaces/document.interface';
import { getFormattedDate } from 'src/utils';

export const mapApiToDocumentWithDate = (doc: IDocumentApi) => ({
  id: uuidv4(),
  fileName: doc.name,
  link: doc.url,
  subtitle: `Загружен ${getFormattedDate(doc.created_at)}`,
  title: doc.name,
});
