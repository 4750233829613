import { makeAutoObservable } from 'mobx';

import { getShortFullName } from 'src/utils';
import {
  APP_LOCALSTORAGE_FIRSTNAME_KEY,
  APP_LOCALSTORAGE_JWT_KEY,
  APP_LOCALSTORAGE_LASTNAME_KEY,
  APP_LOCALSTORAGE_PATRONYMIC_KEY,
} from 'src/constants';
import {
  clearLocalStorageUserData,
  getLocalStorageItem,
  setLocalStorageItem,
} from 'src/helpers/localStorageHelper';

class GlobalAppStore {
  init() {
    this.checkToken();
  }

  token = getLocalStorageItem(APP_LOCALSTORAGE_JWT_KEY);
  firstName = getLocalStorageItem(APP_LOCALSTORAGE_FIRSTNAME_KEY);
  lastName = getLocalStorageItem(APP_LOCALSTORAGE_LASTNAME_KEY);
  patronymic = getLocalStorageItem(APP_LOCALSTORAGE_PATRONYMIC_KEY);

  isLoading = false;

  constructor() {
    makeAutoObservable(this);

    this.logOut = this.logOut.bind(this);
  }

  get userShortName() {
    return getShortFullName({
      lastName: this.lastName,
      firstName: this.firstName,
      patronymic: this.patronymic,
    });
  }

  setUserName(lastname: string, firstname: string, patronymic: string) {
    this.lastName = lastname;
    this.firstName = firstname;
    this.patronymic = patronymic;
  }

  setToken(token: string) {
    this.token = token;
    setLocalStorageItem(APP_LOCALSTORAGE_JWT_KEY, token);
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  logOut() {
    this.setToken('');
    clearLocalStorageUserData();
  }

  checkToken() {
    const TOKEN = process.env.REACT_APP_TOKEN;

    if (!this.token && TOKEN) {
      this.setToken(TOKEN);
    }
  }
}

export default new GlobalAppStore();
