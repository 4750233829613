import React, { SyntheticEvent } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import AgreementStatusBadge from 'src/components/ui/agreement-status-badge';
import Button from 'src/components/ui/button';
import ButtonIcon from 'src/components/ui/button-icon';
import Loader from 'src/components/common/loader';
import PaymentStatusBadge from 'src/components/ui/payment-status-badge';
import { ReactComponent as CrossIcon } from 'src/assets/icons/cross.svg';
import { ReactComponent as DownloadIcon } from 'src/assets/icons/download.svg';

import constantsStore from 'src/stores/constants-store';
import newAgreementStore from 'src/stores/new-agreement-store';

import { AppRoute, Key } from 'src/constants';

import './agreement-tab-view.scss';

const TABLE_HEADERS = ['Дата', 'Номер', 'Тип', 'Статус', 'Оплата', 'Сумма, ₽', ''];

const MOCK_AGREEMENTS: any[] = [
  {
    id: 'agreement1',
    createdAt: '25.06.2021',
    no: 'O-2PPQT - 1',
    type: 'Изменение',
    status: 'ready',
    paymentStatus: 'unpaid',
    totalPrice: '15 000',
  },
  {
    id: 'agreement2',
    createdAt: '25.06.2021',
    no: 'O-2PPQT - 2',
    type: 'Возврат',
    status: 'signed',
    paymentStatus: 'unpaid',
    totalPrice: '-1 500',
  },
  {
    id: 'agreement3',
    createdAt: '26.06.2021',
    no: 'O-2PPQT - 3',
    type: 'Возврат',
    status: 'canceled',
    paymentStatus: 'paid',
    totalPrice: '-2 700',
  },
  {
    id: 'agreement4',
    createdAt: '27.06.2021',
    no: 'O-2PPQT - 4',
    type: 'Изменение',
    status: 'finished',
    paymentStatus: 'accepted',
    totalPrice: '0',
  },
];

const SUM_CELL_INDEX = 5;

const AgreementTabView = observer(() => {
  const history = useHistory();

  const handleAddButtonClick = async () => {
    await newAgreementStore.createAgreement();

    if (newAgreementStore.agreementId) {
      history.push(`${AppRoute.NEW_AGREEMENT_PAGE_ROUTE}/${newAgreementStore.agreementId}`);
    }
  };

  const handleAgreementClick = (id: string) => {
    window.open(`${AppRoute.AGREEMENT_PAGE_ROUTE}/${id}`, '_blank', 'noopener,noreferrer');
  };

  const handleDeleteButtonClick = (evt: SyntheticEvent, id: string) => {
    evt.stopPropagation();

    // TODO: handler
  };
  const handleDownloadButtonClick = (evt: SyntheticEvent, id: string) => {
    evt.stopPropagation();

    // TODO: handler
  };

  return (
    <div className="agreement-tab-view">
      <div className="agreement-tab-view__wrapper">
        <div className="agreement-tab-view__header">
          <h2 className="agreement-tab-view__title">Дополнительные соглашения к заказу</h2>
          <Button
            className="agreement-tab-view__add-button"
            flattened
            icon={<CrossIcon width="16" height="16" />}
            isDisabled={newAgreementStore.isLoading}
            label="Добавить"
            onClick={handleAddButtonClick}
            theme="filled"
          />
        </div>

        <table className="agreement-tab-view__table">
          <thead className="agreement-tab-view__table-head">
            <tr className="agreement-tab-view__table-row  agreement-tab-view__table-row_head">
              {TABLE_HEADERS.map((item, index) => (
                <th
                  className={classNames(
                    'agreement-tab-view__table-cell  agreement-tab-view__table-cell_head',
                    index === SUM_CELL_INDEX && 'agreement-tab-view__table-cell_sum'
                  )}
                  key={`head-cell${index}`}
                >
                  {item}
                </th>
              ))}
            </tr>
          </thead>

          <tbody className="agreement-tab-view__table-body">
            {MOCK_AGREEMENTS.length > 0 ? (
              MOCK_AGREEMENTS.map(
                ({ id, createdAt, no, type, status, paymentStatus, totalPrice }, index) => {
                  return (
                    <tr
                      className="agreement-tab-view__table-row"
                      key={`row${index}`}
                      tabIndex={0}
                      onKeyPress={(evt) => {
                        if (evt.key === Key.ENTER || evt.key === Key.SPACE) {
                          handleAgreementClick(id);
                        }
                      }}
                      onClick={() => handleAgreementClick(id)}
                    >
                      <td className="agreement-tab-view__table-cell">{createdAt}</td>
                      <td className="agreement-tab-view__table-cell">{no}</td>
                      <td className="agreement-tab-view__table-cell">{type}</td>
                      <td className="agreement-tab-view__table-cell">
                        <AgreementStatusBadge status={status} />
                      </td>
                      <td className="agreement-tab-view__table-cell">
                        <PaymentStatusBadge status={paymentStatus} />
                      </td>
                      <td className="agreement-tab-view__table-cell  agreement-tab-view__table-cell_sum">
                        {totalPrice}
                      </td>
                      <td className="agreement-tab-view__table-cell  agreement-tab-view__table-cell_actions">
                        <ButtonIcon
                          className="agreement-tab-view__icon-btn"
                          ariaLabel="Download"
                          color="theme"
                          icon={<DownloadIcon />}
                          onClick={(evt) => handleDownloadButtonClick(evt, id)}
                        />
                        <ButtonIcon
                          className="agreement-tab-view__icon-btn"
                          ariaLabel="Delete"
                          icon={<CrossIcon />}
                          onClick={(evt) => handleDeleteButtonClick(evt, id)}
                        />
                      </td>
                    </tr>
                  );
                }
              )
            ) : (
              <tr className="agreement-tab-view__table-row  agreement-tab-view__table-row_no-data">
                <td className="agreement-tab-view__table-cell">Нет данных</td>
              </tr>
            )}
            {(constantsStore.isLoading || newAgreementStore.isLoading) && (
              <tr>
                <td className="agreement-tab-view__table-cell">
                  <Loader className="loader_position_overlay" />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
});

export default AgreementTabView;
