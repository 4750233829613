import type { IPerson } from 'src/interfaces/person.interface';

export function getShortFullName({ lastName, firstName, patronymic }: IPerson) {
  if (!lastName) {
    return '';
  }

  return [
    lastName,
    ...[firstName, patronymic].filter(Boolean).map((name) => `${name?.charAt(0)}.`),
  ].join(' ');
}
