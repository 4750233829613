import React from 'react';
import { observer } from 'mobx-react-lite';

import OrderTable from '../order-table';

import orderStore from 'src/stores/order-store';
import preorderStore from 'src/stores/preorder-store';

import type { IOrderTableRow } from '../order-table';
import type { OrderInfoTableProps } from './order-info-table.props';
import { getFormattedDate, getFormattedNumber } from 'src/utils';
import { Unit } from 'src/constants';

const OrderInfoTable = observer(({ isPreOrder }: OrderInfoTableProps) => {
  const orderPackage = isPreOrder
    ? preorderStore?.preorder?.items?.package
    : orderStore?.order?.items?.package;

  if (!orderPackage) {
    return null;
  }
  const { sku, name, price, discount = 0, items } = orderPackage;

  const total = price * (1 - discount);

  const data: IOrderTableRow[] = [
    {
      isPackage: true,
      listCells: [
        sku,
        name,
        Unit.MIXED,
        getFormattedNumber(price),
        getFormattedNumber(discount * 100),
        '1',
        Unit.THING,
        getFormattedNumber(total),
      ],
    },
  ];

  items?.forEach((item) => {
    const { sku, name, finishDate, amount, unit } = item;

    const listCells: string[] = [
      sku,
      name,
      finishDate ? getFormattedDate(finishDate) : '-',
      '-',
      '-',
      `${amount}`,
      unit,
      '-',
    ];

    data.push({ listCells });
  });

  return <OrderTable title="Заказ" data={data} />;
});

export default OrderInfoTable;
