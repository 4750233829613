import type {
  IAgreementItemApi,
  IAgreementPackageApi,
  IAgreementItemsApi,
  IAgreementApi,
  IAgreementItem,
  IAgreementPackage,
  IAgreementItems,
  IAgreement,
} from 'src/interfaces/agreement.interface';

const mapApiToAgreementItem = ({
  calculated_price,
  ...rest
}: IAgreementItemApi): IAgreementItem => ({
  ...rest,
  calculatedPrice: calculated_price,
});

const mapApiToAgreementPackage = ({
  calculated_price,
  items,
  ...rest
}: IAgreementPackageApi): IAgreementPackage => ({
  ...rest,
  items: items ? items.map(mapApiToAgreementItem) : null,
  calculatedPrice: calculated_price,
});

const mapApiToAgreementItems = ({
  package: agreementPackage,
  additional_items,
}: IAgreementItemsApi): IAgreementItems => ({
  package: agreementPackage ? mapApiToAgreementPackage(agreementPackage) : null,
  additionalItems: additional_items ? additional_items.map(mapApiToAgreementItem) : null,
});

export const mapApiToAgreement = ({
  payment_status,
  total_price,
  created_at,
  items,
  ...rest
}: IAgreementApi): IAgreement => ({
  ...rest,
  paymentStatus: payment_status,
  totalPrice: total_price,
  createdAt: created_at,
  items: mapApiToAgreementItems(items),
});
