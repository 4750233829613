import React from 'react';
import QRCode from 'react-qr-code';

import Button from 'src/components/ui/button';
import ButtonIcon from 'src/components/ui/button-icon';
import Radiobox from 'src/components/ui/radiobox';
import { ReactComponent as CopyIcon } from 'src/assets/icons/copy.svg';

import copyToClipborad from 'src/utils/copy-to-clipboard';
import usePaymentTabs from 'src/hooks/use-payment-tabs';
import type { PaymentPageTabsProps } from './payment-page-tabs.props';

import './payment-page-tabs.scss';

const QRCODE_SIZE = 328;
const MOCK_LINK = 'https://openagent.ru/pay.php/23489spk1'; // TODO: replace with api fetched link

const PaymentPageTabs = ({ activeTab }: PaymentPageTabsProps) => {
  const { handleLinkTabSet, handleQrTabSet, isLinkTabActive, isQrTabActive } =
    usePaymentTabs(activeTab);
  const handleLinkClick = () => window.open(MOCK_LINK, '_blank', 'noopener,noreferrer');
  const handleCopyButtonClick = () => copyToClipborad(MOCK_LINK);

  return (
    <div className="payment-page-tabs">
      <div className="payment-page-tabs__header">
        <h1 className="payment-page-tabs__title">Выберите способ оплаты</h1>
        <ul className="payment-page-tabs__options">
          <li className="payment-page-tabs__option">
            <Radiobox
              idx="paymentTabLinkRadio"
              isChecked={isLinkTabActive}
              label="Ссылка для оплаты на сайте"
              name="paymentTab"
              onChange={handleLinkTabSet}
            />
          </li>
          <li className="payment-page-tabs__option">
            <Radiobox
              idx="paymentTabQrRadio"
              isChecked={isQrTabActive}
              label="Оплата через QR-код"
              name="paymentTab"
              onChange={handleQrTabSet}
            />
          </li>
        </ul>
      </div>
      <div className="payment-page-tabs__view">
        {isQrTabActive ? (
          <div className="payment-page-tabs__view-qr">
            <p className="payment-page-tabs__text  payment-page-tabs__text_strong">
              Отсканируйте QR-код при помощи приложения вашего банка
            </p>
            <QRCode size={QRCODE_SIZE} value={MOCK_LINK} />
          </div>
        ) : (
          <div className="payment-page-tabs__view-link">
            <p className="payment-page-tabs__text">
              Ссылка для оплаты сформирована
              <br />
              Вы можете перейти по ней и совершить оплату или скопировать и передать клиенту
            </p>
            <div className="payment-page-tabs__view-link-actions">
              <div className="payment-page-tabs__link">
                <a
                  className="payment-page-tabs__link-text"
                  href={MOCK_LINK}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {MOCK_LINK}
                </a>
                <ButtonIcon
                  className="payment-page-tabs__link-button"
                  ariaLabel="Скопировать ссылку"
                  icon={<CopyIcon />}
                  onClick={handleCopyButtonClick}
                />
              </div>
              <Button
                className="payment-page-tabs__button"
                label="Перейти и оплатить"
                onClick={handleLinkClick}
                theme="filled"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentPageTabs;
