import React from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory, useParams } from 'react-router-dom';

import AgreementHeader from 'src/components/common/agreement-header';
import NewAgreementTable from './components/new-agreement-table';
import Button from 'src/components/ui/button';
import NewAgreementDocuments from './components/new-agreement-documents';
import { ReactComponent as CheckIcon } from 'src/assets/icons/check.svg';

import newAgreementStore from 'src/stores/new-agreement-store';
import popupStore, { POPUP_NAMES } from 'src/stores/popups/popup-store';

import './new-agreement-page.scss';

const NewAgreementPage = observer(() => {
  const history = useHistory();
  const params: { id: string } = useParams();

  React.useEffect(() => {
    if (params.id) {
      newAgreementStore.init(params.id);
    }
  }, [params.id]);

  const handleCloseButtonClick = () => {
    popupStore.showPopup(POPUP_NAMES.CONFIRM_ACTION_POPUP, {
      title: 'Прервать добавление соглашения',
      text: 'Вы уверены, что хотите прервать процесс добавления доп. соглашения к заказу? Все несохраненные данные будут утеряны.',
      submitButtonLabel: 'Прервать добавление соглашения',
      onSubmit: () => history.goBack(),
    });
  };

  const handleSubmitButtonClick = () => {
    // TODO: submit handler
  };

  return (
    <div className="new-agreement-page">
      <AgreementHeader
        onCloseButtonClick={handleCloseButtonClick}
        title={`Новое дополнительное соглашение к заказу ${newAgreementStore.agreement?.no ?? ''}`}
      />

      <div className="new-agreement-page__main scrollbar">
        <NewAgreementTable />

        <NewAgreementDocuments />

        <Button
          className="new-agreement-page__submit-button"
          icon={<CheckIcon />}
          label="Сформировать дополнительное соглашение"
          onClick={handleSubmitButtonClick}
          theme="filled"
        />
      </div>
    </div>
  );
});

export default NewAgreementPage;
