import React from 'react';
import { useParams } from 'react-router-dom';

import './setting-attributes-page.scss';

function SettingAttributesPage() {
  const params: { sku: string } = useParams();

  return (
    <div className="setting-attributes-page">
      Страница установки атрибутов. SKU: {params?.sku ?? ''}
    </div>
  );
}

export default SettingAttributesPage;
