import { AgreementStore } from './agreement-store';

import { ApiResponseStatus } from 'src/constants';
import type {
  IAgreement,
  IAgreementApi,
  IAgreementItem,
  IAgreementPackage,
} from 'src/interfaces/agreement.interface';
import type { IDocumentFile } from 'src/interfaces/document-file.interface';
import { mapApiToAgreement } from 'src/adapters';
import { AGREEMENT_API_MOCK } from 'src/stores/mocks/agreement-api-mock';
import { doPost } from 'src/api';

class NewAgreementStore extends AgreementStore {
  constructor() {
    super();

    this.uploadAgreementDocuments = this.uploadAgreementDocuments.bind(this);
  }

  async createAgreement() {
    this.setIsLoading(true);
    // FIXME: remove mock
    try {
      const result: { status: number; data: IAgreementApi } = await new Promise((res) => {
        setTimeout(() => {
          res({ status: ApiResponseStatus.SUCCESS, data: AGREEMENT_API_MOCK });
        }, 400);
      });

      if (result.status === ApiResponseStatus.SUCCESS) {
        this.setAgreement(mapApiToAgreement(result.data));
      } else {
        this.setAgreement(null);
      }
    } catch (e) {
      this.setAgreement(null);
      console.log(e);
    }
    this.setIsLoading(false);
  }

  deleteItem(id: string, isPackage: boolean) {
    // FIXME: add request
    const agreement = { ...(this.agreement as IAgreement) };
    const items = { ...agreement.items };
    const agreementPackage = { ...(items.package ?? {}) };
    const additionalItems = [...(items.additionalItems ?? [])];

    let listItems = isPackage
      ? [...(agreementPackage.items as IAgreementItem[])]
      : [...(additionalItems as IAgreementItem[])];

    const index = listItems.findIndex((item) => item.id === id);

    if (index > -1) {
      const item = { ...listItems[index] };
      item.deleted = true;
      listItems = [...listItems.slice(0, index), item, ...listItems.slice(index + 1)];

      if (isPackage) {
        agreementPackage.items = listItems;
        items.package = agreementPackage as IAgreementPackage;
        agreement.items = items;
      } else {
        items.additionalItems = listItems;
        agreement.items = items;
      }

      this.setAgreement(agreement as IAgreement);
    }
  }

  restoreItem(id: string, isPackage: boolean) {
    // FIXME: add request
    const agreement = { ...(this.agreement as IAgreement) };
    const items = { ...agreement.items };
    const agreementPackage = { ...(items.package ?? {}) };
    const additionalItems = [...(items.additionalItems ?? [])];

    let listItems = isPackage
      ? [...(agreementPackage.items as IAgreementItem[])]
      : [...(additionalItems as IAgreementItem[])];

    const index = listItems.findIndex((item) => item.id === id);

    if (index > -1) {
      const item = { ...listItems[index] };
      item.deleted = false;
      listItems = [...listItems.slice(0, index), item, ...listItems.slice(index + 1)];

      if (isPackage) {
        agreementPackage.items = listItems;
        items.package = agreementPackage as IAgreementPackage;
        agreement.items = items;
      } else {
        items.additionalItems = listItems;
        agreement.items = items;
      }

      this.setAgreement(agreement as IAgreement);
    }
  }

  async uploadDocument(id: string, { file, document }: IDocumentFile) {
    try {
      if (file && document) {
        const formData = new FormData();
        formData.append('type', document);
        formData.append('file', file);
        const headers = { 'Content-Type': 'multipart/form-data' };
        await doPost(`agreements/${id}/documents`, formData, headers); // TODO: check request when api is ready
      }
    } catch (err) {
      console.log(err);
    }
  }

  async uploadAgreementDocuments(files: IDocumentFile[]) {
    const id = this.agreement?.id;
    if (!id) {
      return;
    }

    this.setIsDocumentsLoading(true);

    for (const file of files) {
      await this.uploadDocument(id, file);
    }

    this.loadAgreementDocuments(id);
  }
}

export default new NewAgreementStore();
