import React from 'react';
import classNames from 'classnames';

import UnitComponent from 'src/components/common/unit-component/unit-component';

import type { OrderTableProps } from './order-table.props';

import './order-table.scss';

const TABLE_HEADERS = [
  'Артикул',
  'Наименование',
  'Дата оказания',
  'Цена, ₽',
  'Скидка',
  'Кол-во',
  'Ед.',
  'Сумма, ₽',
];

const CellNumber = {
  NAME: 1,
  UNITS: 6,
};

function OrderTable({ data, isExtraTable, title, total }: OrderTableProps) {
  const tableClasses = classNames(
    'order-table__table table',
    isExtraTable && 'order-table__table_extra'
  );

  return (
    <div className="order-table">
      <h2 className="order-table__title">{title}</h2>

      <table className={tableClasses}>
        <thead>
          <tr className="order-table__row">
            {TABLE_HEADERS.map((item, index) => (
              <th className="table__head-cell" key={`head-cell${index}`}>
                {item}
              </th>
            ))}
          </tr>
        </thead>

        <tbody className="order-table__body">
          {data.map(({ isPackage, listCells }, index) => {
            const rowClasses = classNames(
              'order-table__row',
              isPackage && 'order-table__row_package'
            );

            return (
              <tr className={rowClasses} key={`row${index}`}>
                {listCells.map((cell, index) => {
                  const cellClasses = classNames(
                    'table__cell',
                    isPackage && 'table__cell_package',
                    index === CellNumber.NAME && 'table__cell_text'
                  );

                  return (
                    <td className={cellClasses} key={`cell${index}`}>
                      {index === CellNumber.UNITS ? <UnitComponent value={cell ?? ''} /> : cell}
                    </td>
                  );
                })}
              </tr>
            );
          })}

          {isExtraTable ? (
            <tr className="order-table__total-row">
              <td className="order-table__total-cell" colSpan={TABLE_HEADERS.length}>
                <span className="order-table__total-wrapper">
                  <span className="order-table__total-text">Итого:</span>
                  <span className="order-table__total-text order-table__total-text_medium">
                    {total}
                  </span>
                </span>
              </td>
            </tr>
          ) : null}
        </tbody>
      </table>
    </div>
  );
}

export default OrderTable;
