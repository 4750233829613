import React from 'react';
import { observer } from 'mobx-react-lite';
import { v4 as uuidv4 } from 'uuid';

import Button from 'src/components/ui/button';
import Input from 'src/components/ui/input';
import LinkButton from '../link-button';
import MessageField from '../message-field';

import authStore from 'src/stores/auth-store';

import { Key, INPUT_CODE_MASK } from 'src/constants';
import { getCodeMask } from 'src/utils';

const CodeInputForm = observer(() => {
  const isCheckCodeAvailable = authStore.isCodeValid;

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === Key.ENTER && isCheckCodeAvailable) {
      authStore.checkFormCode();
    }
  };

  return (
    <>
      <div className="login-page__input-wrapper">
        <Input
          id={uuidv4()}
          isValid={!authStore.isCodeFailed}
          mask={INPUT_CODE_MASK}
          maskPlaceholder="X"
          onChange={authStore.setCode}
          onKeyPress={handleKeyPress}
          placeholder="Код из СМС"
          renderCustomMaskText={getCodeMask}
          value={authStore.code}
        />

        <div className="login-page__buttons-wrapper">
          <LinkButton
            isDisabled={authStore.isNewCodeRequestDisabled}
            label="Отправить код еще раз"
            onClick={authStore.getNewVerificationCode}
          />

          <span className="login-page__divider"></span>

          <LinkButton label="Изменить номер телефона" onClick={authStore.resetPhone} />
        </div>
      </div>

      <div className="login-page__submit-button-wrapper">
        <Button
          isDisabled={!isCheckCodeAvailable}
          label="Войти"
          onClick={authStore.checkFormCode}
          theme="filled"
          type="submit"
        />

        <MessageField />
      </div>
    </>
  );
});

export default CodeInputForm;
