import React from 'react';

import AgreementAttribute from '../agreement-attribute';
import UnitComponent from '../unit-component';

import type { ServiceCellProps } from './service-cell.props';

import './service-cell.scss';

function ServiceCell({ amount, attributes = {}, name, unit }: ServiceCellProps) {
  return (
    <span className="service-cell">
      <span className="service-cell__name">{name}</span>

      <span className="service-cell__attributes-wrapper">
        {Object.entries(attributes).map(([attributeType, attributeValue], index) => (
          <AgreementAttribute
            attributeType={attributeType}
            attributeValue={attributeValue}
            key={`${attributeType}-${index}`}
          />
        ))}

        <span className="service-cell__attribute">
          Количество: {amount ?? 0} <UnitComponent value={unit ?? ''} />
        </span>
      </span>
    </span>
  );
}

export default ServiceCell;
