import React from 'react';
import classNames from 'classnames';

import ButtonIcon from 'src/components/ui/button-icon';
import DocumentIcon from '../document-icon';
import { ReactComponent as DownloadIcon } from 'src/assets/icons/download.svg';

import type { DocumentItemProps } from './document-item.props';

import './document-item.scss';

const DocumentItem = ({ className, document, fileName = '' }: DocumentItemProps) => {
  const { title, subtitle, link } = document;
  return (
    <div className={classNames('document-item', className)}>
      <div className="document-item__main-info">
        {fileName && <DocumentIcon className="document-item__icon" fileName={fileName} />}
        <span className="document-item__title">{title}</span>
        <ButtonIcon
          className="document-item__link"
          ariaLabel="Open file"
          color="theme"
          icon={<DownloadIcon />}
          tag="a"
          to={link}
        />
      </div>
      {subtitle && <span className="document-item__subtitle">{subtitle}</span>}
    </div>
  );
};

export default DocumentItem;
