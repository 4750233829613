import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import Button from 'src/components/ui/button';
import DocumentViewSection from 'src/pages/order-page/components/document-view-section';
import DocumentsList from 'src/components/common/documents-list';
import FileUploader from 'src/pages/order-page/components/file-uploader';
import UploadedFile from 'src/pages/order-page/components/uploaded-file';
import { ReactComponent as IconClip } from 'src/assets/icons/paperclip.svg';

import constantsStore from 'src/stores/constants-store';
import documentsStore from 'src/stores/documents-store';

import useDocumentsUpload from 'src/hooks/use-documents-upload';
import { MAX_FILE_SIZE_MB } from 'src/constants';
import type { IDocumentFile } from 'src/interfaces/document-file.interface';

import './document-tab-view.scss';

const DocumentTabView = observer(() => {
  const {
    checkFormFailure,
    files,
    filesInputRef,
    handleFilesUpload,
    handleFilesError,
    isAnyFileLoaded,
  } = useDocumentsUpload();

  const orderDocuments = documentsStore.orderDocuments;
  const preorderDocuments = documentsStore.preorderDocuments;

  useEffect(() => {
    handleFilesUpload([]);
  }, [orderDocuments, handleFilesUpload]);

  const handleUploadButtonClick = () => {
    if (files.length > 0) {
      documentsStore.uploadOrderDocuments(files);
    }
  };

  return (
    <div className="document-tab-view">
      <div className="document-tab-view__wrapper">
        <h2 className="document-tab-view__title">Приложенные документы и файлы</h2>
        <FileUploader
          className="document-tab-view__file-uploader"
          handleFilesUpload={handleFilesUpload}
          handleError={handleFilesError}
          isFormLoading={documentsStore.isLoading || constantsStore.isLoading}
          files={files}
          maxSize={MAX_FILE_SIZE_MB}
          ref={filesInputRef}
        />
        {isAnyFileLoaded && (
          <DocumentViewSection className="document-tab-view__section" title="Ожидают загрузки">
            <div className="document-tab-view__uploaded-files">
              {files.map((file: IDocumentFile) => (
                <UploadedFile
                  key={file.id}
                  className="document-tab-view__uploaded-file"
                  files={files}
                  file={file}
                  handleFilesUpload={handleFilesUpload}
                  isDisabled={documentsStore.isLoading}
                />
              ))}
            </div>
            <Button
              className="document-tab-view__add-button"
              flattened
              icon={<IconClip />}
              isDisabled={checkFormFailure() || documentsStore.isLoading}
              label="Добавить файлы к заказу"
              onClick={handleUploadButtonClick}
              theme="filled"
            />
          </DocumentViewSection>
        )}
        {orderDocuments.length > 0 && (
          <>
            <div className="document-tab-view__divider" />
            <DocumentViewSection className="document-tab-view__section" title="Документы к заказу">
              <DocumentsList className="document-tab-view__documents-list" list={orderDocuments} />
            </DocumentViewSection>
          </>
        )}
        {preorderDocuments.length > 0 && (
          <>
            <div className="document-tab-view__divider" />
            <DocumentViewSection
              className="document-tab-view__section"
              title="Документы к предзаказу"
            >
              <DocumentsList
                className="document-tab-view__documents-list"
                list={preorderDocuments}
              />
            </DocumentViewSection>
          </>
        )}
      </div>
    </div>
  );
});

export default DocumentTabView;
