import type { IPreOrderApi, IPreOrder } from 'src/interfaces/pre-order.interface';
import {
  mapApiToClient,
  mapApiToDeceased,
  mapApiToItems,
  mapApiToOrderPackage,
  mapApiToReferrer,
} from 'src/adapters';

export const mapApiToPreOrder = ({
  referrer,
  client,
  deceased,
  created_at,
  total_price,
  items,
  ...rest
}: IPreOrderApi): IPreOrder => ({
  ...rest,
  referrer: mapApiToReferrer(referrer),
  client: mapApiToClient(client),
  deceased: mapApiToDeceased(deceased),
  createdAt: created_at,
  totalPrice: total_price,
  items: {
    package: items?.package ? mapApiToOrderPackage(items.package) : null,
    additionalItems: items?.additional_items ? mapApiToItems(items.additional_items) : null,
  },
});
