import React from 'react';
import { useParams, useHistory } from 'react-router-dom';

import Tab from 'src/components/ui/tab';

import { OrderTabsConfig } from 'src/constants';

import './tabs.scss';

function Tabs() {
  const history = useHistory();
  const params: { id: string; tab: string } = useParams();

  return (
    <nav className="tabs">
      {OrderTabsConfig.map(({ label, tab, route }) => (
        <Tab
          key={tab}
          isActive={tab === (params.tab || '')}
          label={label}
          onClick={() => history.push(route.replace(':id', params.id))}
        />
      ))}
    </nav>
  );
}

export default Tabs;
