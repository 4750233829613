import React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import Comment from '../comment';
import CommentInput from '../comment-input';
import Loader from 'src/components/common/loader';

import chatStore from 'src/stores/chat-store';

import type { ChatProps } from './chat.props';

import './chat.scss';

const Chat = observer(({ isPreOrder }: ChatProps) => {
  const chatClasses = classNames('chat', isPreOrder && 'chat_disabled');

  const comments = isPreOrder ? chatStore.preOrderComments : chatStore.orderComments;
  const isLoading = isPreOrder
    ? chatStore.isPreOrderCommentsLoading
    : chatStore.isOrderCommentsLoading;

  const messagesWrapper = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    if (messagesWrapper.current) {
      messagesWrapper.current.scrollTop = messagesWrapper.current.scrollHeight;
    }
  }, [comments]);

  return (
    <div className={chatClasses}>
      <h3 className="chat__title">
        Комментарии <span className="chat__title-gray">{comments.length}</span>
      </h3>

      <div className="chat__messages-wrapper scrollbar" ref={messagesWrapper}>
        {comments.map((message, index) => (
          <Comment message={message} key={`${message.createdAt}_${index}`} />
        ))}
      </div>

      {!isPreOrder ? <CommentInput /> : null}
      {isLoading ? <Loader className="loader_position_overlay " /> : null}
    </div>
  );
});

export default Chat;
