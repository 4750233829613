import React from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';

import AgreementStatusBadge from 'src/components/ui/agreement-status-badge';
import Button from 'src/components/ui/button';
import ButtonIcon from 'src/components/ui/button-icon';
import Dropbox from 'src/components/ui/dropbox';
import PaymentStatusBadge from 'src/components/ui/payment-status-badge';
import { ReactComponent as ArchiveIcon } from 'src/assets/icons/archive.svg';
import { ReactComponent as BurgerIcon } from 'src/assets/icons/burger.svg';
import { ReactComponent as CoinIcon } from 'src/assets/icons/coin.svg';
import { ReactComponent as CrossIcon } from 'src/assets/icons/cross.svg';
import { ReactComponent as PencilIcon } from 'src/assets/icons/pencil.svg';
import { ReactComponent as PrintIcon } from 'src/assets/icons/print.svg';

import popupStore, { POPUP_NAMES } from 'src/stores/popups/popup-store';

import type { AgreementMetaProps } from './agreement-meta.props';
import { AppRoute } from 'src/constants';

import './agreement-meta.scss';

const AgreementMeta = ({ data }: AgreementMetaProps) => {
  const history = useHistory();

  if (!data) {
    return null;
  }

  const { id, status, paymentStatus } = data;

  const mockedSubmitAction = () => {
    // TODO: bind to api success action result
    popupStore.showPopup(POPUP_NAMES.INFO_POPUP, {
      title: 'Соглашение подписано!',
      text: 'Соглашение успешно подписано, статус изменен.',
    });
  };

  const actions = [
    {
      id: uuidv4(),
      list: [
        {
          id: uuidv4(),
          icon: <PencilIcon />,
          label: 'Подписать соглашение',
          clickHandler: () => {
            popupStore.showPopup(POPUP_NAMES.CONFIRM_ACTION_POPUP, {
              title: 'Подписать соглашение',
              text: 'Вы уверены, что хотите подписать соглашение и изменить его статус на “Подписано”?',
              submitButtonLabel: 'Подписать',
              onSubmit: mockedSubmitAction,
            });
          },
        },
        {
          id: uuidv4(),
          icon: <CoinIcon />,
          label: 'Оплатить заказ',
          clickHandler: () => history.push(`${AppRoute.AGREEMENT_PAGE_ROUTE}/${id}/payment`),
        },
      ],
    },
  ];

  const burgerActions = [
    {
      id: uuidv4(),
      list: [
        {
          id: uuidv4(),
          icon: <PrintIcon />,
          label: 'Распечатать соглашение',
          clickHandler: () => console.log('запрос на печать соглашения'), // TODO
        },
        {
          id: uuidv4(),
          icon: <PrintIcon />,
          label: 'Распечатать акт',
          clickHandler: () => console.log('запрос на печать акта'), // TODO
        },
      ],
    },
    {
      id: uuidv4(),
      list: [
        {
          id: uuidv4(),
          icon: <ArchiveIcon />,
          label: 'Аннулировать доп. соглашение',
          clickHandler: () => {
            popupStore.showPopup(POPUP_NAMES.CONFIRM_ACTION_POPUP, {
              title: 'Аннулировать дополнительное соглашение',
              text: 'Вы уверены, что хотите аннулировать дополнительное соглашение? Эти изменения нельзя отменить.',
              submitButtonLabel: 'Аннулировать доп. соглашение',
              onSubmit: () => console.log('запрос на аннулирование'), // TODO
            });
          },
        },
      ],
    },
  ];

  return (
    <div className="agreement-meta">
      <div className="agreement-meta__statuses">
        <AgreementStatusBadge status={status} />
        <PaymentStatusBadge status={paymentStatus} />
      </div>
      <div className="agreement-meta__actions">
        <Dropbox
          toggleRender={(toggleMenu, toggleClassName) => (
            <Button
              className={classNames('agreement-meta__action-button', toggleClassName)}
              flattened
              label="Подписать / Оплатить"
              onClick={toggleMenu}
              theme="filled"
            />
          )}
          menuSections={actions}
          position="background"
        />
        <Dropbox
          toggleRender={(toggleMenu, toggleClassName, isMenuOpened) => (
            <ButtonIcon
              className={classNames(
                'agreement-meta__menu-toggle',
                isMenuOpened && 'agreement-meta__menu-toggle_active',
                toggleClassName
              )}
              ariaLabel="Переключатель меню"
              icon={isMenuOpened ? <CrossIcon /> : <BurgerIcon />}
              onClick={toggleMenu}
            />
          )}
          menuSections={burgerActions}
        />
      </div>
    </div>
  );
};

export default AgreementMeta;
