export interface IPersonApi {
  firstname: string;
  lastname: string;
  patronymic?: string;
}

export interface IPerson {
  firstName: string;
  lastName: string;
  patronymic?: string;
}

export const mapApiToPerson = ({ firstname, lastname, patronymic }: IPersonApi): IPerson => ({
  firstName: firstname,
  lastName: lastname,
  patronymic,
});
