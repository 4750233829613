import React from 'react';
import classNames from 'classnames';

import type { ILinkButtonProps } from './link-button.props';

import './link-button.scss';

function LinkButton({ label, href, isDisabled, onClick }: ILinkButtonProps) {
  const className = classNames('link-button', isDisabled && 'link-button_disabled');

  if (href) {
    return (
      <a className={className} href={href} target="_blank" rel="noopener noreferrer">
        {label}
      </a>
    );
  }

  return (
    <button className={className} onClick={onClick} disabled={isDisabled}>
      {label}
    </button>
  );
}

export default LinkButton;
