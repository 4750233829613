import { makeAutoObservable, reaction } from 'mobx';

import globalAppStore from './global-app-store';

import type { IAttribute } from 'src/interfaces/attribute.interface';
import { ApiResponseStatus } from 'src/constants';
import { capitalizeFirstLetter } from 'src/utils';
import { doGet } from 'src/api';

interface IStatusList {
  [propertyName: string]: { title: string };
}

interface IConstants {
  catalog: {
    categories: any;
  };
  order: {
    document_types: string[];
    statuses: IStatusList;
    payment_statuses: IStatusList;
  };
  agreement: {
    statuses: IStatusList;
    payment_statuses: IStatusList;
  };
  preorder: {
    statuses: IStatusList;
  };
}

class ConstantsStore {
  init() {
    this.loadAttributes();
    this.loadConstants();
  }

  agreementStatuses: IStatusList = {};
  agreementPaymentStatuses: IStatusList = {};
  attributes: IAttribute[] = [];
  documentTypes: string[] = [];
  orderStatuses: IStatusList = {};
  preorderStatuses: IStatusList = {};

  isLoading = true;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    reaction(
      () => globalAppStore.token,
      (token) => token && this.init(),
      { fireImmediately: true }
    );
  }

  get documentTypesSelectList() {
    return this.documentTypes.map((doc) => ({
      id: doc,
      value: capitalizeFirstLetter(doc),
    }));
  }

  getAttribute(attributeType: string) {
    return this.attributes.find((attribute) => attribute.type === attributeType);
  }

  getOrderStatusLabel(status: string) {
    return this.orderStatuses[status]?.title || '';
  }

  getAgreementStatusLabel(status: string) {
    return this.agreementStatuses[status]?.title || '';
  }

  getPaymentStatusLabel(status: string) {
    return this.agreementPaymentStatuses[status]?.title || '';
  }

  getPreOrderStatusLabel(status: string) {
    return this.preorderStatuses[status]?.title || '';
  }

  parseConstants(constants: IConstants) {
    this.setOrderStatuses(constants?.order?.statuses || {});
    this.setAgreementStatuses(constants?.agreement?.statuses || {});
    this.setAgreementPaymentStatuses(constants?.agreement?.payment_statuses || {});
    this.setDocumentTypes(constants?.order?.document_types || []);
    this.setPreorderStatuses(constants?.preorder?.statuses || {});
  }

  setOrderStatuses(statuses: IStatusList) {
    this.orderStatuses = statuses;
  }

  setAgreementStatuses(statuses: IStatusList) {
    this.agreementStatuses = statuses;
  }

  setAgreementPaymentStatuses(paymentStatuses: IStatusList) {
    this.agreementPaymentStatuses = paymentStatuses;
  }

  setAttributes(attributes: IAttribute[]) {
    this.attributes = attributes;
  }

  setDocumentTypes(documentTypes: string[]) {
    this.documentTypes = documentTypes;
  }

  setPreorderStatuses(preorderStatuses: IStatusList) {
    this.preorderStatuses = preorderStatuses;
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  async loadConstants() {
    this.setIsLoading(true);

    try {
      const result = await doGet('constants');

      if (result.status === ApiResponseStatus.SUCCESS) {
        this.parseConstants(result.data);
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.setIsLoading(false);
    }
  }

  async loadAttributes() {
    try {
      const result = await doGet('attributes');

      if (result.status === ApiResponseStatus.SUCCESS) {
        this.setAttributes(result.data);
      }
    } catch (e) {
      console.log(e);
    }
  }
}

export default new ConstantsStore();
