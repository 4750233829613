import React from 'react';

import ButtonIcon from 'src/components/ui/button-icon';
import DocumentAttachment from '../document-attachment';
import { ReactComponent as RemoveIcon } from 'src/assets/icons/remove.svg';

import type { UploadableAttachmentProps } from './uploadable-attachment.props';
import { isDocumentFile } from 'src/utils';

import './uploadable-attachment.scss';

function UploadableAttachment({
  attachment,
  handleImageClick,
  handleRemoveButtonClick,
}: UploadableAttachmentProps) {
  const { id, file, imageURL } = attachment;
  const { name } = file;

  return (
    <div className="uploadable-attachment">
      {isDocumentFile(file.type) ? (
        <DocumentAttachment fileName={name} />
      ) : (
        <button
          className="uploadable-attachment__image-attachment"
          onClick={() => handleImageClick(id)}
          style={{ backgroundImage: `url(${imageURL}` }}
        />
      )}

      <ButtonIcon
        ariaLabel="Remove attachment"
        className="uploadable-attachment__button-remove"
        icon={<RemoveIcon className="uploadable-attachment__icon-remove" />}
        onClick={() => handleRemoveButtonClick(id)}
      />
    </div>
  );
}

export default UploadableAttachment;
