import { makeAutoObservable } from 'mobx';

import { ApiResponseStatus } from 'src/constants';
import type { IComment, ICommentApi } from 'src/interfaces/comment.interface';
import { mapApiToComment } from 'src/adapters';
import { COMMENTS_API_MOCK } from './mocks/comments-api-mock';
import { doGet, doPost } from 'src/api';

class ChatStore {
  init(id: string) {
    this.setOrderId(id);
    this.loadComments();
    this.loadPreOrderComments();
  }

  orderId: string | null = null;
  orderComments: IComment[] = [];
  preOrderComments: IComment[] = [];
  isOrderCommentsLoading = false;
  isPreOrderCommentsLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  setOrderId(orderId: string) {
    this.orderId = orderId;
  }

  setOrderComments(orderComments: IComment[]) {
    this.orderComments = orderComments;
  }

  setPreorderComments(preOrderComments: IComment[]) {
    this.preOrderComments = preOrderComments;
  }

  setOrderCommentsLoading(isOrderCommentsLoading: boolean) {
    this.isOrderCommentsLoading = isOrderCommentsLoading;
  }

  setPreOrderCommentsLoading(isPreOrderCommentsLoading: boolean) {
    this.isPreOrderCommentsLoading = isPreOrderCommentsLoading;
  }

  async loadComments() {
    this.setOrderCommentsLoading(true);

    try {
      const result = await doGet(`orders/${this.orderId}/comments`);

      if (result.status === ApiResponseStatus.SUCCESS) {
        this.setOrderComments(result.data.map(mapApiToComment));
      }
    } catch (e) {
      console.log(e);
    }

    this.setOrderCommentsLoading(false);
  }

  async loadPreOrderComments() {
    this.setPreOrderCommentsLoading(true);
    // FIXME: remove mock
    try {
      const result: { status: number; data: ICommentApi[] } = await new Promise((res) => {
        setTimeout(() => {
          res({ status: ApiResponseStatus.SUCCESS, data: COMMENTS_API_MOCK });
        }, 400);
      });

      if (result.status === ApiResponseStatus.SUCCESS) {
        this.setPreorderComments(result.data.map(mapApiToComment));
      }
    } catch (e) {
      console.log(e);
    }
    this.setPreOrderCommentsLoading(false);
  }

  async sendComments(value: string, commentAttachments: File[]) {
    this.setOrderCommentsLoading(true);

    try {
      const formData = new FormData();
      formData.append('comment', value);

      if (commentAttachments?.length > 0) {
        commentAttachments.forEach((attachment) => formData.append('files', attachment));
      }

      const headers = { 'Content-Type': 'multipart/form-data' };
      const result = await doPost(`orders/${this.orderId}/comments`, formData, headers);

      if (result.status === ApiResponseStatus.SUCCESS) {
        this.setOrderComments([...this.orderComments, mapApiToComment(result.data)]);
      }
    } catch (e) {
      console.log(e);
    }

    this.setOrderCommentsLoading(false);
  }
}

export default new ChatStore();
