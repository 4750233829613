import React from 'react';
import classNames from 'classnames';

import { ReactComponent as Spinner } from 'src/assets/icons/spinner.svg';
import type { LoaderProps } from './loader.props';

import './loader.scss';

function Loader({ className }: LoaderProps) {
  return (
    <div className={classNames('loader', className)}>
      <Spinner />
    </div>
  );
}

export default Loader;
