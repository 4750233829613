import React from 'react';

import { Key } from 'src/constants';
import useKeyUp from './use-key-up';
import useOnClickOutside from './use-on-click-outside';

export default function useDropbox() {
  const [isMenuOpened, setIsMenuOpened] = React.useState<boolean>(false);
  const wrapperRef = React.useRef<HTMLDivElement | null>(null);

  const closeMenu = () => setIsMenuOpened(false);
  const toggleMenu = () => setIsMenuOpened((prevState) => !prevState);

  useOnClickOutside(wrapperRef, closeMenu, !isMenuOpened);
  useKeyUp(Key.ESCAPE, closeMenu, !isMenuOpened);

  return {
    wrapperRef,
    isMenuOpened,
    toggleMenu,
    closeMenu,
  };
}
