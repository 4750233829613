import { makeAutoObservable } from 'mobx';

import chatStore from 'src/stores/chat-store';
import documentsStore from 'src/stores/documents-store';
import preorderStore from 'src/stores/preorder-store';

import { ApiResponseStatus } from 'src/constants';
import type { IOrder } from 'src/interfaces/order.interface';
import type { IOrderHistory } from 'src/interfaces/order-history.interface';
import { mapApiToOrder, mapApiToOrderHistory } from 'src/adapters';
import { doGet } from 'src/api';

class OrderStore {
  init(id: string) {
    this.loadOrder(id);
    this.loadOrderHistory(id);
    chatStore.init(id);
    documentsStore.init(id);
    preorderStore.loadPreorder(id);
  }

  order?: IOrder;
  orderHistory: IOrderHistory[] = [];
  isOrderLoading = false;
  isOrderHistoryLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  get isInfoTabViewLoading() {
    return this.isOrderLoading || this.isOrderHistoryLoading;
  }

  setOrder(order: IOrder) {
    this.order = order;
  }

  setIsOrderLoading(isOrderLoading: boolean) {
    this.isOrderLoading = isOrderLoading;
  }

  setIsOrderHistoryLoading(isOrderHistoryLoading: boolean) {
    this.isOrderHistoryLoading = isOrderHistoryLoading;
  }

  setOrderHistory(orderHistory: IOrderHistory[]) {
    this.orderHistory = orderHistory;
  }

  async loadOrder(id: string) {
    this.setIsOrderLoading(true);

    try {
      const result = await doGet(`orders/${id}`);

      if (result.status === ApiResponseStatus.SUCCESS) {
        this.setOrder(mapApiToOrder(result.data));
      }
    } catch (e) {
      console.log(e);
    }

    this.setIsOrderLoading(false);
  }

  async loadOrderHistory(id: string) {
    this.setIsOrderHistoryLoading(true);

    try {
      const result = await doGet(`orders/${id}/history`);

      if (result.status === ApiResponseStatus.SUCCESS) {
        this.setOrderHistory(result.data.map(mapApiToOrderHistory));
      }
    } catch (e) {
      console.log(e);
    }

    this.setIsOrderHistoryLoading(false);
  }
}

export default new OrderStore();
