import React from 'react';
import classNames from 'classnames';

import Label from '../label';

import type { IRadioboxProps } from './radiobox.props';

import './radiobox.scss';

function Radiobox({
  idx,
  isChecked,
  isDisabled,
  isValid = true,
  label,
  name,
  onChange,
}: IRadioboxProps) {
  const id = `radiobox_${idx}`;
  const value = label || idx;

  const labelClasses = classNames('radiobox__label', !label && 'radiobox__label_nolabel');

  return (
    <div
      className={classNames(
        'radiobox',
        isChecked && 'radiobox_checked',
        isDisabled && 'radiobox_disabled',
        !isValid && 'radiobox_invalid'
      )}
    >
      <input
        checked={isChecked}
        className="radiobox__input"
        disabled={isDisabled}
        id={id}
        name={name}
        onChange={() => onChange(value || '')}
        type="radio"
      />
      <Label
        id={id}
        className={labelClasses}
        label={
          <>
            <span className="radiobox__icon" />
            {!!label ? label : null}
          </>
        }
      />
    </div>
  );
}

export default Radiobox;
