import React from 'react';
import classNames from 'classnames';

import type { PlaceholderProps } from './placeholder.props';

import './placeholder.scss';

function Placeholder({ className, placeholder, hide }: PlaceholderProps) {
  const classes = classNames('placeholder', className);

  return !hide && placeholder ? <span className={classes}>{placeholder}</span> : null;
}

export default Placeholder;
