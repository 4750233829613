import { mapApiToPerson } from '../interfaces/person.interface';
import type {
  IClient,
  IClientApi,
  IOtherPreOrder,
  IOtherPreOrderApi,
} from 'src/interfaces/other-pre-order.interface';

const mapApiToClient = ({ phone, ...person }: IClientApi): IClient => ({
  ...mapApiToPerson(person),
  phone,
});

const mapApiToOtherPreOrder = ({
  client,
  service_place,
  created_at,
  total_price,
  ...rest
}: IOtherPreOrderApi): IOtherPreOrder => {
  return {
    client: mapApiToClient(client),
    servicePlace: service_place,
    createdAt: created_at,
    totalPrice: total_price,
    ...rest,
  };
};

export const mapApiToOtherPreOrders = (items: IOtherPreOrderApi[]): IOtherPreOrder[] => {
  return items.map(mapApiToOtherPreOrder);
};
