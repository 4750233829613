export const AppRoute = {
  LOGIN_PAGE_ROUTE: '/login',
  MAIN_PAGE_ROUTE: '/',
  SEARCH_ORDERS_PAGE_ROUTE: '/search',
  ORDER_PAGE_ROUTE: '/order/:id',
  ORDER_PAGE_TAB_ROUTE: '/order/:id/:tab',
  AGREEMENT_PAGE_ROUTE: '/agreement',
  NEW_AGREEMENT_PAGE_ROUTE: '/new-agreement',
  SETTING_ATTRIBUTES_PAGE: '/setting-attributes-page',
};

export enum OrderTabRoute {
  INFO_TAB = '/order/:id',
  PREORDER_TAB = '/order/:id/pre-order',
  AGREEMENT_TAB = '/order/:id/agreement',
  DOCUMENT_TAB = '/order/:id/document',
}
