import React from 'react';

import { Unit } from 'src/constants';
import type { UnitComponentProps } from './unit-component.props';

import './unit-component.scss';

function UnitComponent({ value }: UnitComponentProps) {
  return (
    {
      [Unit.CUBIC_METER]: (
        <span className="unit-component">
          м<sup className="unit-component__exponent">3</sup>
        </span>
      ),
      [Unit.HOUR]: <span className="unit-component">час</span>,
      [Unit.KILOGRAM]: <span className="unit-component">кг</span>,
      [Unit.METER]: <span className="unit-component">метр</span>,
      [Unit.SQUARE_DECIMETER]: (
        <span className="unit-component">
          дм<sup className="unit-component__exponent">2</sup>
        </span>
      ),
      [Unit.SQUARE_METER]: (
        <span className="unit-component">
          м<sup className="unit-component__exponent">2</sup>
        </span>
      ),
      [Unit.THING]: <span className="unit-component">шт.</span>,
    }[value] ?? ''
  );
}

export default UnitComponent;
