import React from 'react';
import classNames from 'classnames';

import type { TabProps } from './tab.props';

import './tab.scss';

function Tab({ ariaLabel, className, icon, isActive, isDisabled, label, onClick }: TabProps) {
  const classes = classNames('tab', className, {
    tab_active: isActive,
    tab_disabled: isDisabled,
    'tab_with-icon': !!icon,
  });

  return (
    <button aria-label={ariaLabel} className={classes} disabled={isDisabled} onClick={onClick}>
      {!!icon ? <span className="tab__icon">{icon}</span> : null}

      <span className="tab__label">{label}</span>
    </button>
  );
}

export default Tab;
