import React from 'react';

import PreOrderStatusBadge from 'src/components/ui/pre-order-status-badge';
import { ReactComponent as BurialIcon } from 'src/assets/icons/burial.svg';
import { ReactComponent as FarewellIcon } from 'src/assets/icons/farewell.svg';

import { getFormattedCurrency, getFormattedPhone, getFullName } from 'src/utils';
import type {
  IClient,
  IOtherPreOrder,
  IServicePlace,
} from 'src/interfaces/other-pre-order.interface';
import type { IPerson } from 'src/interfaces/person.interface';

const renderServicePlace = (servicePlace: IServicePlace) => {
  if (!servicePlace) {
    return '-';
  }

  if (servicePlace.cemetery) {
    return (
      <>
        <BurialIcon />
        {servicePlace.cemetery?.name || '-'}
      </>
    );
  }

  if (servicePlace.farewell) {
    return (
      <>
        <FarewellIcon />
        {servicePlace.farewell?.name || '-'}
      </>
    );
  }
};

const renderClientInfo = (client: IClient) => (
  <>
    <span className="other-preorders-table__table-cell_name">{getFullName(client as IPerson)}</span>
    <span className="other-preorders-table__table-cell_phone">
      {getFormattedPhone(client.phone)}
    </span>
  </>
);

const renderStatus = (status: string) => <PreOrderStatusBadge status={status} />;

export const tableConfig: {
  label: string;
  field: keyof IOtherPreOrder;
  className: string;
  renderFn?: (val: any) => any;
}[] = [
  {
    label: '№',
    field: 'no',
    className: 'no',
  },
  {
    label: 'Контакт',
    field: 'client',
    className: 'client',
    renderFn: renderClientInfo,
  },
  {
    label: 'Место оказания',
    field: 'servicePlace',
    className: 'service-place',
    renderFn: renderServicePlace,
  },
  {
    label: 'Добавлено',
    field: 'createdAt',
    className: 'created-at',
  },
  {
    label: 'Сумма, ₽',
    field: 'totalPrice',
    className: 'total-price',
    renderFn: (amount: number) => getFormattedCurrency(amount, 'decimal'),
  },
  {
    label: 'Статус',
    field: 'status',
    className: 'status',
    renderFn: renderStatus,
  },
];
