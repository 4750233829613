import React from 'react';
import classNames from 'classnames';

import type { LabelProps } from './label.props';

import './label.scss';

function Label({ label, id, className, theme, ...props }: LabelProps) {
  const classes = classNames('label', className, theme && `label_${theme}`);

  return label ? (
    <label className={classes} htmlFor={id} {...props}>
      {label}
    </label>
  ) : null;
}

export default Label;
