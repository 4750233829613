import React, { SyntheticEvent } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import Button from 'src/components/ui/button';
import OrderSearchInput from 'src/components/common/order-search-input';
import { ReactComponent as SignOutIcon } from 'src/assets/icons/sign-out.svg';

import globalAppStore from 'src/stores/global-app-store';
import searchOrderStore from 'src/stores/search-order-store';
import popupStore, { POPUP_NAMES } from 'src/stores/popups/popup-store';

import useKeyUp from 'src/hooks/use-key-up';
import type { IHeaderProps } from './header-props';
import { Key } from 'src/constants';

import './header.scss';

const Header = observer(({ extended }: IHeaderProps) => {
  useKeyUp(Key.ENTER, searchOrderStore.doSearch);

  const handleLogoutClick = () => {
    popupStore.showPopup(POPUP_NAMES.CONFIRM_ACTION_POPUP, {
      title: 'Выход',
      text: 'Вы уверены, что хотите выйти из системы?',
      submitButtonLabel: 'Выход',
      onSubmit: globalAppStore.logOut,
    });
  };

  const handleSubmit = (e: SyntheticEvent) => e.preventDefault();

  const className = classNames('header', { header_extended: extended });

  return (
    <header className={className}>
      <Button
        icon={<SignOutIcon className="header__sign-out-icon" />}
        className="header__sign-out-button"
        label={globalAppStore.userShortName}
        onClick={handleLogoutClick}
        theme="outlined"
      />
      {extended && (
        <>
          <form autoComplete="off" className="header__form" onSubmit={handleSubmit}>
            <OrderSearchInput />
          </form>
          <Button
            className="header__search-button"
            label="Искать"
            onClick={searchOrderStore.doSearch}
            theme="filled"
            isDisabled={
              !searchOrderStore.isSearchStringValid || searchOrderStore.orderDataStore.isLoading
            }
          />
        </>
      )}
    </header>
  );
});

export default Header;
