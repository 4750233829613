import { v4 as uuidv4 } from 'uuid';

import type { IDocumentApi } from 'src/interfaces/document.interface';

export const mapApiToDocument = (doc: IDocumentApi) => ({
  id: uuidv4(),
  fileName: doc.name,
  link: doc.url,
  subtitle: doc.name,
  title: doc.type,
});
