import React from 'react';

import DropboxMenu from './components/dropbox-menu';

import type { DropboxProps } from './dropbox.props';
import useDropbox from 'src/hooks/use-dropbox';

import './dropbox.scss';

const Dropbox = ({ toggleRender, menuSections = [], position = 'bottom' }: DropboxProps) => {
  const { wrapperRef, isMenuOpened, toggleMenu, closeMenu } = useDropbox();

  return (
    <div className="dropbox" ref={wrapperRef}>
      {toggleRender(toggleMenu, 'dropbox__toggle', isMenuOpened)}
      {isMenuOpened && (
        <DropboxMenu menuSections={menuSections} closeMenu={closeMenu} position={position} />
      )}
    </div>
  );
};

export default Dropbox;
