import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import type { ButtonProps } from './button-icon.props';

import './button-icon.scss';

const ButtonIcon = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      ariaLabel,
      className,
      color = 'default',
      icon,
      isDisabled,
      onClick,
      onKeyPress,
      tag = 'button',
      target,
      to = '',
      type = 'button',
      ...props
    },
    ref
  ) => {
    const buttonClasses = classNames(
      'button-icon',
      tag === 'button' || 'button-icon_reset_link',
      isDisabled && 'button-icon_disabled',
      color && `button-icon_color_${color}`,
      className
    );

    switch (tag) {
      case 'button':
        return (
          <button
            aria-label={ariaLabel}
            className={buttonClasses}
            disabled={isDisabled}
            onClick={onClick}
            onKeyPress={onKeyPress}
            ref={ref}
            type={type}
            {...props}
          >
            {icon}
          </button>
        );
      case 'link':
        return (
          <Link
            aria-label={ariaLabel}
            className={buttonClasses}
            onClick={onClick}
            target={target || '_self'}
            to={to}
            {...props}
          >
            {icon}
          </Link>
        );
      case 'a':
        return (
          <a
            className={buttonClasses}
            href={to}
            onClick={onClick}
            rel="noopener noreferrer"
            target={target || '_blank'}
            {...props}
          >
            {icon}
          </a>
        );
      default:
        return null;
    }
  }
);

export default ButtonIcon;
