import React from 'react';
import classNames from 'classnames';

import { ReactComponent as Arrow } from 'src/assets/icons/chevron.svg';

import useOnClickOutside from 'src/hooks/use-on-click-outside';
import useKeyUp from 'src/hooks/use-key-up';
import { Key } from 'src/constants';
import type { SelectProps } from './select.props';

import './select.scss';

const Select = React.forwardRef<HTMLButtonElement, SelectProps>(
  ({ className, flattened, label, value, items, isDisabled, onChange }: SelectProps, ref) => {
    const [isOpen, setIsOpen] = React.useState<boolean>(false);

    const selectRef = React.useRef<HTMLDivElement | null>(null);
    const closeMenu = () => setIsOpen(false);

    useOnClickOutside(selectRef, closeMenu, !isOpen);
    useKeyUp(Key.ESCAPE, closeMenu, !isOpen);

    const handleClick = () => setIsOpen(!isOpen);

    const handleChange = (id: string) => {
      onChange(id);
      setIsOpen(false);
    };

    const currentValueLabel = items.find((item) => item.id === value)?.value || '';

    const selectClasses = classNames(
      'select',
      {
        select_disabled: isDisabled,
        select_open: isOpen,
        select_flattened: flattened,
      },
      className
    );

    return (
      <div className={selectClasses} ref={selectRef}>
        <button
          type="button"
          disabled={isDisabled}
          onClick={handleClick}
          className="select__button"
          ref={ref}
        >
          <span className="select__value">{currentValueLabel || label || ''}</span>
          <Arrow className="select__arrow" />
        </button>
        {isOpen && (
          <div className="select__dropbox scrollbar">
            <ul className="select__dropbox-wrapper">
              {items.map((item) => {
                const itemLabel = item.value;

                const classes = classNames('select__dropbox-item-button', {
                  'select__dropbox-item-button_active': item.id === value,
                  'select__dropbox-item-button_empty': !itemLabel,
                });
                return (
                  <li key={item.id} className="select__dropbox-item">
                    <button className={classes} onClick={() => handleChange(item.id)}>
                      {item.value}
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    );
  }
);

export default Select;
