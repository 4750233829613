import React from 'react';

import { ReactComponent as FileIcon } from 'src/assets/icons/file.svg';

import type { DocumentAttachmentProps } from './document-attachment.props';

import './document-attachment.scss';

function DocumentAttachment({ isDownloaded = false, fileName, url }: DocumentAttachmentProps) {
  if (isDownloaded) {
    return (
      <a className="document-attachment" href={url} rel="noopener noreferrer" target="_blank">
        <span className="document-attachment__icon">
          <FileIcon />
        </span>

        <span className="document-attachment__file-name">{fileName}</span>
      </a>
    );
  }

  return (
    <div className="document-attachment document-attachment_uploadable" tabIndex={0}>
      <span className="document-attachment__icon">
        <FileIcon />
      </span>

      <span className="document-attachment__file-name">{fileName}</span>
    </div>
  );
}

export default DocumentAttachment;
