import { makeAutoObservable, reaction } from 'mobx';

import DataStore from './base/data-store';

import type { IOrder, IOrderApi } from 'src/interfaces/order.interface';
import { mapApiToOrders } from 'src/adapters';
import { MIN_SEARCH_STRING_LENGTH, SEARCH_TIMEOUT, SEARCH_ORDERS_MAX } from 'src/constants';

class SearchOrderStore {
  init({ limit = SEARCH_ORDERS_MAX } = {}) {
    this.orderDataStore = this.createOrdersDataStore({ limit });
    this.doSearch();
  }

  orderDataStore = new DataStore<IOrder>({});

  searchString = '';
  isOrderAutoload = false;
  isLoading = false;

  searchTimer: NodeJS.Timeout | null = null;

  constructor() {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      }
    );

    reaction(
      () => this.searchString,
      () => this.searchAutoLoadOrders()
    );
  }

  get orders() {
    return this.orderDataStore.getData;
  }

  get isVisibleSearchResult() {
    return this.isOrderAutoload && this.isSearchStringValid && !this.isLoading;
  }

  get isSearchStringValid() {
    return this.searchString.trim().length >= MIN_SEARCH_STRING_LENGTH;
  }

  get isAllDataLoaded() {
    return this.orders.length >= this.orderDataStore.total;
  }

  setSearchString(str: string) {
    this.searchString = str;
  }

  setIsOrderAutoload(autoload: boolean) {
    this.isOrderAutoload = autoload;
  }

  setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  searchAutoLoadOrders() {
    if (!this.isOrderAutoload) {
      return;
    }

    this.clearSearchTimer();

    if (this.isSearchStringValid) {
      this.searchTimer = setTimeout(this.doSearch, SEARCH_TIMEOUT);
    }
  }

  searchResultLoadOrders(entries: { isIntersecting: boolean }[]) {
    if (entries.length && entries[0].isIntersecting && !this.isAllDataLoaded) {
      this.orderDataStore.loadNextPage();
    }
  }

  doSearch() {
    this.clearSearchTimer();

    if (this.isSearchStringValid) {
      this.applySearchFilter();
    }
  }

  applySearchFilter() {
    const searchStr = this.searchString.trim();

    this.orderDataStore.addFilter('filter', { search_str: searchStr });
  }

  clearSearchString() {
    this.setSearchString('');
    this.clearSearchTimer();
    this.orderDataStore.setData([]);
  }

  clearSearchTimer() {
    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
    }
  }

  createOrdersDataStore({ limit }: { limit: number }) {
    return new DataStore<IOrder>({
      url: 'orders',
      pagingProps: {
        limit,
      },
      listeners: {
        onDataLoad: (store: typeof DataStore, data: IOrderApi[]) => mapApiToOrders(data),
      },
    });
  }
}

export default new SearchOrderStore();
