import React from 'react';

import type { DropboxItemProps } from './dropbox-item.props';

import './dropbox-item.scss';

const DropboxItem = ({ closeMenu, data }: DropboxItemProps) => {
  const { clickHandler, label, icon } = data;

  return (
    <li className="dropbox-item">
      <button
        className="dropbox-item__button"
        onClick={() => {
          clickHandler();
          closeMenu();
        }}
      >
        {icon && <span className="dropbox-item__icon">{icon}</span>}
        <span className="dropbox-item__label">{label}</span>
      </button>
    </li>
  );
};

export default DropboxItem;
