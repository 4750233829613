import React from 'react';

import UploadableAttachment from '../uploadable-attachment';

import popupStore, { POPUP_NAMES } from 'src/stores/popups/popup-store';

import type { UploadableAttachmentsProps } from './uploadable-attachments.props';

import './uploadable-attachments.scss';

function UploadableAttachments({
  attachments,
  handleRemoveButtonClick,
}: UploadableAttachmentsProps) {
  const handleImageClick = (id: string) => {
    const images = attachments
      .filter(({ imageURL }) => !!imageURL)
      .map(({ id, imageURL, file }) => ({
        id,
        name: file.name,
        thumbUrl: imageURL,
        url: imageURL,
      }));

    const index = images.findIndex((image) => image.id === id);

    popupStore.showPopup(POPUP_NAMES.IMAGE_VIEWER_POPUP, {
      index,
      images,
    });
  };

  return (
    <div className="uploadable-attachments">
      {attachments.map((attachment) => (
        <UploadableAttachment
          key={attachment.id}
          attachment={attachment}
          handleImageClick={handleImageClick}
          handleRemoveButtonClick={handleRemoveButtonClick}
        />
      ))}
    </div>
  );
}

export default UploadableAttachments;
