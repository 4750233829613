const copyToClipboardFallback = (text: string) => {
  return new Promise(function (resolve: (val?: any) => void, reject) {
    const range = document.createRange();
    range.selectNodeContents(document.body);
    document.getSelection()?.addRange(range);

    let success = false;
    function listener(evt: ClipboardEvent) {
      evt.preventDefault();
      evt.clipboardData?.setData('text/plain', text);
      success = true;
    }
    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);
    document.getSelection()?.removeAllRanges();
    success ? resolve() : reject();
  });
};

const copyToClipborad = (text: string) => {
  if (!navigator.clipboard) {
    copyToClipboardFallback(text);
    return;
  }
  navigator.clipboard.writeText(text);
};

export default copyToClipborad;
