import React from 'react';
import { observer } from 'mobx-react-lite';

import Chat from '../../components/chat';
import ClientCard from 'src/components/common/client-card';
import DeceasedCard from 'src/components/common/deceased-card';
import EmptyData from 'src/components/common/empty-data';
import ExtraOrderTable from '../../components/extra-order-table';
import Loader from 'src/components/common/loader';
import OrderInfoTable from '../../components/order-info-table';
import OtherPreordersTable from '../../components/other-preorders-table';
import { ReactComponent as ClipboardIcon } from 'src/assets/icons/clipboard.svg';

import preorderStore from 'src/stores/preorder-store';

import type { IPerson } from 'src/interfaces/person.interface';
import type { IReferrer } from 'src/interfaces/referrer.interface';
import { getFormattedCurrency, getFormattedPhone, getShortFullName } from 'src/utils';

import './preorder-tab-view.scss';

const PreorderTabView = observer(() => {
  const preorder = preorderStore.preorder;
  const referrer = preorder?.referrer || {};
  const no = (referrer as IReferrer)?.no || '';
  const phone = (referrer as IReferrer)?.phone || '';

  if (preorderStore.isPreOrderLoading) {
    return <Loader className="loader_position_overlay " />;
  }

  return (
    <div className="preorder-tab-view">
      {preorderStore.isPreorderNotFound ? (
        <EmptyData icon={<ClipboardIcon />} text="Предзаказ отсутствует" />
      ) : (
        <>
          <div className="preorder-tab-view__header">
            <div className="preorder-tab-view__header-info">
              <div className="preorder-tab-view__header-title">
                Предзаказ {preorder?.no} от {preorder?.createdAt}
              </div>
              <div className="preorder-tab-view__header-label">Общая стоимость:</div>
              <div className="preorder-tab-view__header-amount">
                {getFormattedCurrency(preorder?.totalPrice)}
              </div>
            </div>
            <div className="preorder-tab-view__header-meta">
              {[`Создал: ${getShortFullName(referrer as IPerson)}`, no, getFormattedPhone(phone)]
                .filter(Boolean)
                .join(' / ')}
            </div>
          </div>
          <div className="preorder-tab-view__body">
            <div className="preorder-tab-view__row">
              <DeceasedCard isPreOrder />

              <ClientCard isPreOrder />

              <OrderInfoTable isPreOrder />

              <ExtraOrderTable isPreOrder />

              <Chat isPreOrder />

              <OtherPreordersTable />
            </div>
          </div>
        </>
      )}
    </div>
  );
});

export default PreorderTabView;
