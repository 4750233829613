import React from 'react';
import { observer } from 'mobx-react-lite';

import Chat from '../../components/chat';
import ClientCard from 'src/components/common/client-card';
import DeceasedCard from 'src/components/common/deceased-card';
import HistoryCard from '../../components/history-card';
import PlacementCard from '../../components/placement-card';
import ExtraOrderTable from '../../components/extra-order-table';
import Loader from 'src/components/common/loader';
import OrderInfoTable from '../../components/order-info-table';

import orderStore from 'src/stores/order-store';

import './info-tab-view.scss';

const InfoTabView = observer(() => {
  if (orderStore.isInfoTabViewLoading) {
    return <Loader className="loader_position_overlay " />;
  }

  return (
    <div className="info-tab-view">
      <div className="info-tab-view__row">
        <DeceasedCard />
        <ClientCard />
      </div>

      <PlacementCard />

      <OrderInfoTable />

      <ExtraOrderTable />

      <HistoryCard />

      <Chat />
    </div>
  );
});

export default InfoTabView;
