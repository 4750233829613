import dayjs from 'dayjs';

import { DATE_FORMAT, TIME_FORMAT } from 'src/constants';

export function getFormattedDate(date: string) {
  if (!date) {
    return '';
  }

  return dayjs(date).format(DATE_FORMAT);
}

export function getFormattedTime(date: string) {
  if (!date) {
    return '';
  }

  return dayjs(date).format(TIME_FORMAT);
}
