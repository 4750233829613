import type { IClientApi, IClient } from 'src/interfaces/client.interface';
import { mapApiToIdentity } from './map-api-to-identity';

export const mapApiToClient = ({
  firstname,
  lastname,
  identity,
  email,
  ...rest
}: IClientApi): IClient => ({
  ...rest,
  firstName: firstname,
  lastName: lastname,
  identity: identity ? mapApiToIdentity(identity) : null,
  email: email ? email : null,
});
