import React from 'react';
import classNames from 'classnames';

import type { OrderCardProps } from './order-card.props';

import './order-card.scss';

function OrderCard({ className, data, title }: OrderCardProps) {
  const classes = classNames('order-card', className);

  return (
    <div className={classes}>
      <h2 className="order-card__title">{title}</h2>

      {data.map(({ icon, texts }, index) => (
        <div className="order-card__row" key={`row_${title}_${index}`}>
          <span className="order-card__icon">{icon}</span>

          {texts.map(({ isAdditionalText, text }, index) => (
            <div
              key={`text_${text}_${index}`}
              className={classNames(
                'order-card__text',
                isAdditionalText && 'order-card__text_gray'
              )}
            >
              {text}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

export default OrderCard;
