import React from 'react';
import { Redirect, useLocation, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Loader from 'src/components/common/loader';
import PaymentPageDocuments from './components/payment-page-documents';
import PaymentPageTabs from './components/payment-page-tabs';

import agreementStore from 'src/stores/agreement-store';

import { AppRoute, PaymentTab } from 'src/constants';
import { getFormattedCurrency } from 'src/utils';

import './payment-page.scss';

const MOCK_ORDER_DATA = {
  // TODO: need to be fetched from api
  no: 'O-2PPQT',
  createdAt: '25.06.2021',
};

const PaymentPage = observer(() => {
  const params: { id: string } = useParams();
  const { state: { activeTab } = { activeTab: PaymentTab.LINK } } =
    useLocation<{ activeTab: PaymentTab }>();
  const agreement = agreementStore.agreement;

  const { id, no: agreementNo, totalPrice = 0, paymentStatus } = agreement || {};
  const { no: orderNo, createdAt } = MOCK_ORDER_DATA || {};

  React.useEffect(() => {
    if (params.id && !agreement) {
      agreementStore.loadAgreement(params.id);
    }
  }, [params.id, agreement]);

  if (!agreement) {
    return <Loader className="loader_position_overlay" />;
  }

  if (paymentStatus !== 'unpaid') {
    return <Redirect to={`${AppRoute.AGREEMENT_PAGE_ROUTE}/${id}`} />;
  }

  return (
    <div className="payment-page  scrollbar">
      <div className="payment-page__top">
        <div className="payment-page__header">
          <p className="payment-page__header-title">
            <span className="payment-page__header-text">Дополнительное соглашение</span>
            <span className="payment-page__header-data">
              № {agreementNo} к договору {orderNo} от {createdAt}
            </span>
          </p>
          <p className="payment-page__header-title  payment-page__header-title_right">
            <span className="payment-page__header-text">К оплате</span>
            <span className="payment-page__header-price">{getFormattedCurrency(totalPrice)}</span>
          </p>
        </div>
        {agreementStore.noSubtitleDocuments && (
          <PaymentPageDocuments documents={agreementStore.noSubtitleDocuments} />
        )}
      </div>
      <PaymentPageTabs activeTab={activeTab} />
    </div>
  );
});

export default PaymentPage;
