import React from 'react';
import classNames from 'classnames';

import ButtonIcon from 'src/components/ui/button-icon';
import { ReactComponent as ArrowIcon } from 'src/assets/icons/chevron.svg';

import type { ThumbsButtonProps } from './thumbs-button.props';

import './thumbs-button.scss';

function ThumbsButton({
  className,
  handleButtonClick,
  isButtonDisabled,
  isInitialFocused,
  modifier,
}: ThumbsButtonProps) {
  const buttonRef = React.useRef<HTMLButtonElement | null>(null);

  React.useEffect(() => {
    if (buttonRef.current && isInitialFocused) {
      buttonRef.current.focus();
    }
  }, [isInitialFocused]);

  return (
    <ButtonIcon
      ariaLabel={`Show ${modifier} image`}
      className={classNames(
        `thumbs-button thumbs-button_${modifier}`,
        className,
        isButtonDisabled && 'thumbs-button_disabled'
      )}
      icon={<ArrowIcon className="thumbs-button__icon" />}
      isDisabled={isButtonDisabled}
      onClick={handleButtonClick}
      ref={buttonRef}
    />
  );
}

export default ThumbsButton;
