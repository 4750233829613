import React from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';

import Button from 'src/components/ui/button';
import FileUploader from 'src/pages/order-page/components/file-uploader';
import UploadedFile from 'src/pages/order-page/components/uploaded-file';

import popupStore, { POPUP_NAMES } from 'src/stores/popups/popup-store';

import useOnClickOutside from 'src/hooks/use-on-click-outside';
import useDocumentsUpload from 'src/hooks/use-documents-upload';
import useKeyUp from 'src/hooks/use-key-up';
import { Key, MAX_FILE_SIZE_MB } from 'src/constants';
import type { IDocumentFile } from 'src/interfaces/document-file.interface';

import '../popup.scss';

const UPLOAD_POPUP = POPUP_NAMES.UPLOAD_POPUP;

export const UploadPopup = observer(() => {
  const popupRef = React.useRef<HTMLDivElement | null>(null);

  const { isVisible, onSubmit } = popupStore.getPopupState(UPLOAD_POPUP);
  const {
    checkFormFailure,
    files,
    filesInputRef,
    handleFilesUpload,
    handleFilesError,
    isAnyFileLoaded,
  } = useDocumentsUpload();

  const closePopup = () => {
    popupStore.hidePopup(UPLOAD_POPUP);
  };

  const handleSubmit = () => {
    closePopup();
    onSubmit?.(files);
  };

  useOnClickOutside(popupRef, closePopup, !isVisible);
  useKeyUp(Key.ESCAPE, closePopup, !isVisible);

  React.useEffect(() => {
    handleFilesUpload([]);
  }, [handleFilesUpload, isVisible]);

  return (
    <div
      className={classNames('popup popup_upload scrollbar', isVisible && 'popup_visible')}
      ref={popupRef}
    >
      <div className="popup__title">Загрузите подтверждающие документы</div>
      <div className="popup__text">
        Укажите тип документа, который вы хотите загрузить. Если подходящий тип отсутствует,
        выберите “Другое”.
      </div>
      <FileUploader
        className="popup__file-uploader"
        files={files}
        handleFilesUpload={handleFilesUpload}
        handleError={handleFilesError}
        maxSize={MAX_FILE_SIZE_MB}
        ref={filesInputRef}
      />
      {isAnyFileLoaded && (
        <div className="popup__files">
          {files.map((file: IDocumentFile) => (
            <UploadedFile
              key={file.id}
              className="popup__files-item"
              files={files}
              file={file}
              location="popup"
              handleFilesUpload={handleFilesUpload}
            />
          ))}
        </div>
      )}
      <div className="popup__actions">
        <Button
          onClick={handleSubmit}
          label="Загрузить"
          theme="filled"
          isDisabled={checkFormFailure() || !isAnyFileLoaded}
        />
        <Button onClick={closePopup} label="Отмена" theme="borderless" />
      </div>
    </div>
  );
});

export default UploadPopup;
