import { BrowserRouter as Router, Redirect, Switch } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import AllPopups from 'src/components/app/all-popups';
import AlertBlock from 'src/components/common/alert-block';

import globalAppStore from 'src/stores/global-app-store';

import { LogInPageRoutes, MainPageRoutes } from 'src/routes';
import { AppRoute } from 'src/constants';

import './scss/index.scss';

globalAppStore.init();

const App = observer(() => {
  return (
    <div className="app">
      <Router>
        {globalAppStore.token ? (
          <Switch>
            {MainPageRoutes()}
            <Redirect to={AppRoute.MAIN_PAGE_ROUTE} />
          </Switch>
        ) : (
          <Switch>
            {LogInPageRoutes()}
            <Redirect to={AppRoute.LOGIN_PAGE_ROUTE} />
          </Switch>
        )}
      </Router>
      <div className="app__shadow" />
      <AlertBlock />
      <AllPopups />
    </div>
  );
});

export default App;
