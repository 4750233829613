import { makeAutoObservable, reaction } from 'mobx';

import DataStore from 'src/stores/base/data-store';
import orderStore from './order-store';

import { ApiResponseStatus } from 'src/constants';
import type { IOtherPreOrder, IOtherPreOrderApi } from 'src/interfaces/other-pre-order.interface';
import type { IPreOrder } from 'src/interfaces/pre-order.interface';
import { mapApiToPreOrder, mapApiToOtherPreOrders } from 'src/adapters';
import { doGet } from 'src/api';
import { getFullName } from 'src/utils';

class PreorderStore {
  preorder?: IPreOrder;
  isPreOrderLoading = false;
  isPreorderNotFound = false;

  preordersDataStore = new DataStore<IOtherPreOrder>({
    url: 'preorders',
    listeners: {
      onDataLoad: (_store: DataStore<IOtherPreOrder>, data: IOtherPreOrderApi[]) =>
        mapApiToOtherPreOrders(data),
    },
  });

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => orderStore?.order?.deceased,
      (deceased) => {
        if (deceased) {
          this.applySearchFilter(getFullName(deceased));
        }
      }
    );
  }

  get preorders() {
    return this.preordersDataStore.getData;
  }

  setPreorder(preorder: IPreOrder) {
    this.preorder = preorder;
  }

  setIsPreOrderLoading(isPreOrderLoading: boolean) {
    this.isPreOrderLoading = isPreOrderLoading;
  }

  setIsPreorderNotFound(isPreorderNotFound: boolean) {
    this.isPreorderNotFound = isPreorderNotFound;
  }

  async loadPreorder(id: string) {
    this.setIsPreOrderLoading(true);

    try {
      const result = await doGet(`orders/${id}/preorder`);

      if (result.status === ApiResponseStatus.SUCCESS) {
        this.setPreorder(mapApiToPreOrder(result.data));
      }
    } catch (e) {
      console.log(e);
    }

    this.setIsPreOrderLoading(false);
  }

  applySearchFilter(searchStr: string) {
    this.preordersDataStore.addFilter('filter', { search_str: searchStr });
  }
}

export default new PreorderStore();
