import React from 'react';
import { observer } from 'mobx-react-lite';
import { v4 as uuidv4 } from 'uuid';

import Button from 'src/components/ui/button';
import Input from 'src/components/ui/input';

import authStore from 'src/stores/auth-store';

import { Key, INPUT_PHONE_MASK } from 'src/constants';
import { getPhoneMask } from 'src/utils';

const PhoneInputForm = observer(() => {
  const isCheckPhoneAvailable = authStore.isPhoneValid;

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === Key.ENTER && isCheckPhoneAvailable) {
      authStore.getVerificationCode();
    }
  };

  return (
    <>
      <Input
        id={uuidv4()}
        mask={INPUT_PHONE_MASK}
        maskPlaceholder="X"
        onChange={authStore.setPhone}
        onKeyPress={handleKeyPress}
        placeholder="Номер телефона"
        renderCustomMaskText={getPhoneMask}
        value={authStore.phone}
      />

      <Button
        isDisabled={!isCheckPhoneAvailable}
        label="Войти"
        onClick={authStore.getVerificationCode}
        theme="filled"
        type="submit"
      />
    </>
  );
});

export default PhoneInputForm;
