import { makeAutoObservable } from 'mobx';

import orderStore from './order-store';

import { ApiResponseStatus } from 'src/constants';
import type { IDocumentFile } from 'src/interfaces/document-file.interface';
import type { IDocument, IDocumentApi } from 'src/interfaces/document.interface';
import { mapApiToDocument, mapApiToDocumentWithDate } from 'src/adapters';
import { doGet, doPost } from 'src/api';

class DocumentsStore {
  init(id: string) {
    this.loadOrderDocuments(id);
    this.loadPreorderDocuments(id);
  }

  orderDocuments: IDocument[] = [];
  preorderDocuments: IDocument[] = [];
  isLoading = false;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  setOrderDocuments(documents: IDocument[]) {
    this.orderDocuments = documents;
  }

  setPreorderDocuments(documents: IDocument[]) {
    this.preorderDocuments = documents;
  }

  async loadOrderDocuments(id: string) {
    try {
      const result = await doGet(`orders/${id}/documents`);

      if (result.status === ApiResponseStatus.SUCCESS) {
        const mappedData = result.data.map((it: IDocumentApi) => mapApiToDocument(it));
        this.setOrderDocuments(mappedData);
      }
    } catch (e) {
      console.log(e);
    }
  }

  async loadPreorderDocuments(id: string) {
    try {
      const result = await doGet(`orders/${id}/preorder/documents`);

      if (result.status === ApiResponseStatus.SUCCESS) {
        const mappedData = result.data.map((it: IDocumentApi) => mapApiToDocumentWithDate(it));
        this.setPreorderDocuments(mappedData);
      }
    } catch (e) {
      console.log(e);
    }
  }

  async uploadDocument(id: string, { file, document }: IDocumentFile) {
    try {
      if (file && document) {
        const formData = new FormData();
        formData.append('type', document);
        formData.append('file', file);
        const headers = { 'Content-Type': 'multipart/form-data' };
        await doPost(`orders/${id}/documents`, formData, headers);
      }
    } catch (e) {
      console.log(e);
    }
  }

  async uploadOrderDocuments(files: IDocumentFile[]) {
    const id = orderStore.order?.id;
    if (!id) {
      return;
    }

    this.setLoading(true);

    for (const file of files) {
      await this.uploadDocument(id, file);
    }

    this.loadOrderDocuments(id);
    this.setLoading(false);
  }
}

export default new DocumentsStore();
