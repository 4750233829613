import React from 'react';

import Tabs from '../tabs';
import OrderStatusBadge from 'src/components/ui/order-status-badge';

import './order-header.scss';

function OrderHeader({
  no,
  status,
  createdAt,
}: {
  no?: string;
  status?: string;
  createdAt?: string;
}) {
  return (
    <header className="order-header">
      <div className="order-header__info">
        <div className="order-header__info-no">
          <h1 className="order-header__no">Заказ {no}</h1>
          <OrderStatusBadge status={status} />
        </div>
        <div className="order-header__info-meta">
          <span className="order-header__owner">Мой заказ</span>
          <span className="order-header__date">Создан {createdAt}</span>
        </div>
      </div>
      <Tabs />
    </header>
  );
}

export default OrderHeader;
