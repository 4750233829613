import type { IDeceasedApi, IDeceased } from 'src/interfaces/deceased.interface';
import { mapApiToCertificate } from './map-api-to-certificate';

export const mapApiToDeceased = ({
  firstname,
  lastname,
  certificate,
  ...rest
}: IDeceasedApi): IDeceased => ({
  ...rest,
  firstName: firstname,
  lastName: lastname,
  certificate: certificate ? mapApiToCertificate(certificate) : null,
});
