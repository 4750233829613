import React from 'react';
import { observer } from 'mobx-react-lite';

import CodeInputForm from './components/code-input-form';
import LinkButton from './components/link-button';
import PhoneInputForm from './components/phone-input-form';
import { ReactComponent as LogoIcon } from 'src/assets/icons/logo.svg';

import authStore from 'src/stores/auth-store';

import { LoginStep } from 'src/constants/login-step';
import { version } from '../../../package.json';

import './login-page.scss';

const LoginPage = observer(() => {
  return (
    <div className="login-page">
      <div className="login-page__main">
        <LogoIcon height={134} width={164} />

        <div className="login-page__controls-wrapper">
          {authStore.loginStep === LoginStep.PHONE_INPUT ? <PhoneInputForm /> : <CodeInputForm />}
        </div>
      </div>

      <div className="login-page__footer">
        <div className="login-page__phone">8 (495) 150-21-12</div>

        <LinkButton label="support@openagent.ru" href="mailto:support@openagent.ru" />

        <div className="login-page__version">Версия {version}</div>
      </div>
    </div>
  );
});

export default LoginPage;
