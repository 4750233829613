import { OrderTabRoute } from './app-routes';

const OrderTabLabel = {
  INFO_TAB: 'Информация',
  PREORDER_TAB: 'Предзаказ',
  AGREEMENT_TAB: 'Доп. соглашения',
  DOCUMENT_TAB: 'Документы',
};

const OrderTabId = {
  INFO_TAB: '',
  PREORDER_TAB: 'pre-order',
  AGREEMENT_TAB: 'agreement',
  DOCUMENT_TAB: 'document',
};

export const OrderTabsConfig = [
  {
    label: OrderTabLabel.INFO_TAB,
    tab: OrderTabId.INFO_TAB,
    route: OrderTabRoute.INFO_TAB,
  },
  {
    label: OrderTabLabel.PREORDER_TAB,
    tab: OrderTabId.PREORDER_TAB,
    route: OrderTabRoute.PREORDER_TAB,
  },
  {
    label: OrderTabLabel.AGREEMENT_TAB,
    tab: OrderTabId.AGREEMENT_TAB,
    route: OrderTabRoute.AGREEMENT_TAB,
  },
  {
    label: OrderTabLabel.DOCUMENT_TAB,
    tab: OrderTabId.DOCUMENT_TAB,
    route: OrderTabRoute.DOCUMENT_TAB,
  },
];
