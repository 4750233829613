import React from 'react';
import classNames from 'classnames';

import type { DocumentViewSectionProps } from './document-view-section.props';

import './document-view-section.scss';

const DocumentViewSection = ({ className, title, children }: DocumentViewSectionProps) => {
  return (
    <section className={classNames('document-view-section', className)}>
      <h3 className="document-view-section__title">{title}</h3>
      {children}
    </section>
  );
};

export default DocumentViewSection;
