import type { IOrder, IOrderApi } from 'src/interfaces/order.interface';
import {
  mapApiToClient,
  mapApiToDeceased,
  mapApiToItems,
  mapApiToOrderPackage,
} from 'src/adapters';

export const mapApiToOrder = ({
  id,
  no,
  client,
  deceased,
  created_at,
  status,
  payment_status,
  total_price,
  service_place,
  items,
}: IOrderApi): IOrder => ({
  id,
  no,
  client: mapApiToClient(client),
  deceased: mapApiToDeceased(deceased),
  createdAt: created_at,
  status,
  paymentStatus: payment_status,
  totalPrice: total_price,
  servicePlace: service_place,
  items: {
    package: items?.package ? mapApiToOrderPackage(items.package) : null,
    additionalItems: items?.additional_items ? mapApiToItems(items.additional_items) : null,
  },
});
