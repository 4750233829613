export function getFormattedCurrency(amount?: number, style: 'currency' | 'decimal' = 'currency') {
  if (!amount) {
    return amount;
  }

  return new Intl.NumberFormat('ru-RU', {
    style,
    currency: 'RUB',
    maximumFractionDigits: 0,
  }).format(amount);
}
