import { v4 as uuidv4 } from 'uuid';

import { AlertType } from 'src/components/ui/alert';

export const getAlertOptions = (title: string) => ({
  id: uuidv4(),
  desc: '',
  timeout: 10000,
  title,
  type: AlertType.error,
});
