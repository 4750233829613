import React from 'react';
import { observer } from 'mobx-react-lite';

import Thumbs from '../thumbs';

import popupStore, { POPUP_NAMES } from 'src/stores/popups/popup-store';

const ImageViewerPhotos = observer(() => {
  const { images, index } = popupStore.getPopupState(POPUP_NAMES.IMAGE_VIEWER_POPUP);

  const [imageCurrentIndex, setImageCurrentIndex] = React.useState(index);

  const imageSrc = images[imageCurrentIndex].url;
  const descriptionPhoto = images[imageCurrentIndex].name ?? '';
  const imageAlt = descriptionPhoto;

  return (
    <>
      <img className="image-viewer__image" src={imageSrc} alt={imageAlt} />

      <h3 className="image-viewer__title">{descriptionPhoto}</h3>

      <Thumbs
        handleImageClick={setImageCurrentIndex}
        imageCurrentIndex={imageCurrentIndex}
        images={images}
      />
    </>
  );
});

export default ImageViewerPhotos;
