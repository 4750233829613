import React from 'react';

import { PaymentTab } from 'src/constants';

export default function usePaymentTabs(activeTab = PaymentTab.LINK) {
  const [paymentTab, setPaymentTab] = React.useState(activeTab);

  const handleLinkTabSet = () => setPaymentTab(PaymentTab.LINK);
  const handleQrTabSet = () => setPaymentTab(PaymentTab.QR_CODE);

  return {
    handleLinkTabSet,
    handleQrTabSet,
    isLinkTabActive: paymentTab === PaymentTab.LINK,
    isQrTabActive: paymentTab === PaymentTab.QR_CODE,
  };
}
