import type { ICommentApi } from 'src/interfaces/comment.interface';

export const COMMENTS_API_MOCK: ICommentApi[] = [
  {
    comment: '25.06 без Лякошель-Торбинсов',
    created_at: '2021-06-23T09:56:01.000Z',
    user: {
      role: 'agent',
      lastname: 'Артемьева',
      firstname: 'Анна',
      patronymic: 'Николаевна',
    },
  },

  {
    comment: 'Текст на гравировке "Вход только по делу насчёт угощения."',
    created_at: '2021-06-23T09:56:02.000Z',
    user: {
      role: 'agent',
      lastname: 'Артемьева',
      firstname: 'Анна',
      patronymic: 'Николаевна',
    },
  },

  {
    comment: 'Постамент для гроба опустить пониже. На прощании будет много низкорослых.”',
    created_at: '2021-06-23T09:56:03.000Z',
    user: {
      role: 'agent',
      lastname: 'Артемьева',
      firstname: 'Анна',
      patronymic: 'Николаевна',
    },
  },

  {
    comment: 'Шатер должен вмещать 200 человек',
    created_at: '2021-06-23T09:56:03.000Z',
    user: {
      role: 'agent',
      lastname: 'Артемьева',
      firstname: 'Анна',
      patronymic: 'Николаевна',
    },
  },

  {
    comment: 'Ограда с узором лориенского леса',
    created_at: '2021-06-23T09:56:03.000Z',
    user: {
      role: 'agent',
      lastname: 'Артемьева',
      firstname: 'Анна',
      patronymic: 'Николаевна',
    },
  },

  {
    comment: 'Памятник планируется вот такой',
    created_at: '2021-06-23T09:56:03.000Z',
    user: {
      role: 'agent',
      lastname: 'Артемьева',
      firstname: 'Анна',
      patronymic: 'Николаевна',
    },
    attachments: [
      {
        mime_type: 'image/jpeg',
        created_at: '2021-12-01T07:10:18.656Z',
        name: '1.jpg',
        url: '/images/1.jpg',
        thumb_url: '/images/1-thumb.jpg',
      },

      {
        mime_type: 'image/png',
        created_at: '2021-12-01T07:10:18.656Z',
        name: '2.png',
        url: '/images/2.png',
        thumb_url: '/images/2-thumb.png',
      },

      {
        mime_type: 'image/png',
        created_at: '2021-12-01T07:10:18.656Z',
        name: '3.png',
        url: '/images/3.png',
        thumb_url: '/images/3-thumb.png',
      },

      {
        mime_type: 'image/png',
        created_at: '2021-12-01T07:10:18.656Z',
        name: '4.png',
        url: '/images/4.png',
        thumb_url: '/images/4-thumb.png',
      },

      {
        mime_type: 'image/png',
        created_at: '2021-12-01T07:10:18.656Z',
        name: '5.png',
        url: '/images/5.png',
        thumb_url: '/images/5-thumb.png',
      },

      {
        mime_type: 'image/png',
        created_at: '2021-12-01T07:10:18.656Z',
        name: '6.png',
        url: '/images/6.png',
        thumb_url: '/images/6-thumb.png',
      },

      {
        mime_type: 'image/png',
        created_at: '2021-12-01T07:10:18.656Z',
        name: '7.png',
        url: '/images/7.png',
        thumb_url: '/images/7-thumb.png',
      },

      {
        mime_type: 'image/jpeg',
        created_at: '2021-12-01T07:10:18.656Z',
        name: '8.jpg',
        url: '/images/8.jpg',
        thumb_url: '/images/8-thumb.jpg',
      },

      {
        mime_type: 'application/pdf',
        created_at: '2021-12-01T07:10:18.656Z',
        name: 'Название файла.pdf',
        url: '/documents/1.pdf',
        thumb_url: '/documents/1.pdf',
      },

      {
        mime_type: 'application/pdf',
        created_at: '2021-12-01T07:10:18.656Z',
        name: 'Еще одно название файла.pdf',
        url: '/documents/2.pdf',
        thumb_url: '/documents/2.pdf',
      },

      {
        mime_type: 'application/pdf',
        created_at: '2021-12-01T07:10:18.656Z',
        name: 'Еще одно название файла.pdf',
        url: '/documents/3.pdf',
        thumb_url: '/documents/3.pdf',
      },
    ],
  },

  {
    comment: '25.06 все еще без Лякошель-Торбинсов',
    created_at: '2021-06-23T09:56:03.000Z',
    user: {
      role: 'agent',
      lastname: 'Артемьева',
      firstname: 'Анна',
      patronymic: 'Николаевна',
    },
  },
];
