import React, { SyntheticEvent } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';

import ButtonIcon from 'src/components/ui/button-icon';
import ServiceCell from 'src/components/common/service-cell';
import { ReactComponent as DeleteIcon } from 'src/assets/icons/delete.svg';
import { ReactComponent as ReturnIcon } from 'src/assets/icons/return.svg';

import newAgreementStore from 'src/stores/new-agreement-store';

import { AppRoute, Key } from 'src/constants';
import { getDiscount, getFormattedCurrency } from 'src/utils';

import './new-agreement-table.scss';

const TABLE_HEADERS = ['Артикул', 'Услуга', 'Стоимость', 'Скидка', 'Со скидкой', ''];

const NewAgreementTable = observer(() => {
  const history = useHistory();

  const { discount = 0 } = newAgreementStore.agreement ?? {};
  const { package: agreementPackage, additionalItems } = newAgreementStore.agreement?.items ?? {};
  const { calculatedPrice, name, price, sku } = agreementPackage ?? {};

  if (!newAgreementStore.agreement) {
    return null;
  }

  const goToSettingAttributesPage = (sku: string) =>
    history.push(`${AppRoute.SETTING_ATTRIBUTES_PAGE}/${sku}`);

  const handleKeyPressButton = (evt: SyntheticEvent) => evt.stopPropagation();

  const handleKeyPressRow = (
    evt: React.KeyboardEvent<HTMLTableRowElement>,
    isAdded: boolean,
    sku: string
  ) => {
    if ((evt.key === Key.ENTER || evt.key === Key.SPACE) && isAdded) {
      goToSettingAttributesPage(sku);
    }
  };

  const handleClickRow = (isAdded: boolean, sku: string) => {
    if (isAdded) {
      goToSettingAttributesPage(sku);
    }
  };

  const handleClickButtonDelete = (evt: SyntheticEvent, id: string, isPackage: boolean) => {
    evt.stopPropagation();
    newAgreementStore.deleteItem(id, isPackage);
  };

  return (
    <div className="new-agreement-table">
      <h2 className="new-agreement-table__title">Товары и услуги в заказе</h2>

      <table className="table new-agreement-table__table">
        <thead className="new-agreement-table__table-head">
          <tr className="new-agreement-table__table-row new-agreement-table__table-row_head">
            {TABLE_HEADERS.map((item, index) => {
              const headClasses = classNames(
                'new-agreement-table__table-head-cell',
                index > 1 && 'new-agreement-table__table-head-cell_right'
              );
              return (
                <th className={headClasses} key={`head-cell${index}`}>
                  {item}
                </th>
              );
            })}
          </tr>
        </thead>

        <tbody className="new-agreement-table__table-body">
          {agreementPackage ? (
            <>
              <tr className="new-agreement-table__table-row">
                <td
                  className="new-agreement-table__table-cell-subtitle"
                  colSpan={TABLE_HEADERS.length}
                >
                  Пакет
                </td>
              </tr>

              <tr className="new-agreement-table__table-row new-agreement-table__table-row_package">
                <td className="new-agreement-table__table-cell new-agreement-table__table-cell_sku">
                  {sku}
                </td>

                <td className="new-agreement-table__table-cell new-agreement-table__table-cell_name">
                  {name}
                </td>

                <td className="new-agreement-table__table-cell new-agreement-table__table-cell_price new-agreement-table__table-cell_right">
                  {getFormattedCurrency(price)}
                </td>

                <td className="new-agreement-table__table-cell new-agreement-table__table-cell_discount new-agreement-table__table-cell_right">
                  {getDiscount(discount)}
                </td>

                <td className="new-agreement-table__table-cell new-agreement-table__table-cell_calculated-price new-agreement-table__table-cell_right">
                  {getFormattedCurrency(calculatedPrice)}
                </td>
              </tr>

              {agreementPackage?.items?.map(
                ({ id, added, amount, attributes, deleted, name, sku, unit }, index) => {
                  const isAdded = !!(added && !deleted);
                  const rowClasses = classNames(
                    'new-agreement-table__table-row',
                    isAdded && 'new-agreement-table__table-row_added',
                    deleted && 'new-agreement-table__table-row_deleted'
                  );

                  return (
                    <tr
                      className={rowClasses}
                      key={`package-row${index}`}
                      onClick={() => handleClickRow(isAdded, sku)}
                      onKeyPress={(evt) => handleKeyPressRow(evt, isAdded, sku)}
                      tabIndex={isAdded ? 0 : -1}
                    >
                      <td className="new-agreement-table__table-cell new-agreement-table__table-cell_sku">
                        {sku}
                      </td>

                      <td
                        className="new-agreement-table__table-cell new-agreement-table__table-cell_name new-agreement-table__table-cell_service"
                        colSpan={4}
                      >
                        <ServiceCell
                          amount={amount}
                          attributes={attributes}
                          name={name}
                          unit={unit}
                        />
                      </td>

                      <td className="new-agreement-table__table-cell">
                        {deleted ? (
                          <ButtonIcon
                            ariaLabel="Return item"
                            icon={<ReturnIcon />}
                            onClick={() => newAgreementStore.restoreItem(id, true)}
                          />
                        ) : (
                          <ButtonIcon
                            ariaLabel="Delete item"
                            icon={<DeleteIcon className="new-agreement-table__delete-icon" />}
                            onClick={(evt) => handleClickButtonDelete(evt, id, true)}
                            onKeyPress={handleKeyPressButton}
                          />
                        )}
                      </td>
                    </tr>
                  );
                }
              )}
            </>
          ) : null}

          {additionalItems ? (
            <>
              <tr className="new-agreement-table__table-row">
                <td
                  className="new-agreement-table__table-cell-subtitle"
                  colSpan={TABLE_HEADERS.length}
                >
                  Товары и услуги
                </td>
              </tr>

              {additionalItems?.map(
                (
                  {
                    id,
                    added,
                    amount,
                    attributes,
                    calculatedPrice,
                    deleted,
                    name,
                    price,
                    sku,
                    unit,
                  },
                  index
                ) => {
                  const isAdded = !!(added && !deleted);
                  const rowClasses = classNames(
                    'new-agreement-table__table-row new-agreement-table__table-row_additional',
                    isAdded && 'new-agreement-table__table-row_added',
                    deleted && 'new-agreement-table__table-row_deleted'
                  );
                  return (
                    <tr
                      className={rowClasses}
                      key={`additional-row${index}`}
                      onClick={() => handleClickRow(isAdded, sku)}
                      onKeyPress={(evt) => handleKeyPressRow(evt, isAdded, sku)}
                      tabIndex={isAdded ? 0 : -1}
                    >
                      <td className="new-agreement-table__table-cell new-agreement-table__table-cell_sku">
                        {sku}
                      </td>

                      <td className="new-agreement-table__table-cell new-agreement-table__table-cell_name">
                        <ServiceCell
                          amount={amount}
                          attributes={attributes}
                          name={name}
                          unit={unit}
                        />
                      </td>

                      <td className="new-agreement-table__table-cell new-agreement-table__table-cell_price new-agreement-table__table-cell_right">
                        {getFormattedCurrency(price)}
                      </td>

                      <td className="new-agreement-table__table-cell new-agreement-table__table-cell_discount new-agreement-table__table-cell_right">
                        {getDiscount(discount)}
                      </td>

                      <td className="new-agreement-table__table-cell new-agreement-table__table-cell_calculated-price new-agreement-table__table-cell_right">
                        {getFormattedCurrency(calculatedPrice)}
                      </td>

                      <td className="new-agreement-table__table-cell">
                        {deleted ? (
                          <ButtonIcon
                            ariaLabel="Return item"
                            icon={<ReturnIcon />}
                            onClick={() => newAgreementStore.restoreItem(id, false)}
                          />
                        ) : (
                          <ButtonIcon
                            ariaLabel="Delete item"
                            icon={<DeleteIcon className="new-agreement-table__delete-icon" />}
                            onClick={(evt) => handleClickButtonDelete(evt, id, false)}
                            onKeyPress={handleKeyPressButton}
                          />
                        )}
                      </td>
                    </tr>
                  );
                }
              )}
              <tr className="new-agreement-table__table-row new-agreement-table__table-row_total">
                <td
                  colSpan={TABLE_HEADERS.length}
                  className="new-agreement-table__table-cell-total"
                >
                  Итого:{' '}
                  <span className="new-agreement-table__total-value">
                    {getFormattedCurrency(newAgreementStore.agreement?.totalPrice ?? 0)}
                  </span>
                </td>
              </tr>
            </>
          ) : null}
        </tbody>
      </table>
    </div>
  );
});

export default NewAgreementTable;
