import React from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';

import OrderList from 'src/pages/main-page/components/order-list';
import Button from 'src/components/ui/button';

import searchOrderStore from 'src/stores/search-order-store';

import { AppRoute } from 'src/constants';

import './order-search-result-popup.scss';

const OrderSearchResultPopup = observer(() => {
  const history = useHistory();

  if (!searchOrderStore.orders.length) {
    return (
      <div className="order-search-result-popup order-search-result-popup_empty">
        Заказы не найдены
      </div>
    );
  }

  return (
    <div className="order-search-result-popup">
      <OrderList orders={searchOrderStore.orders} />
      <Button
        className="order-search-result-popup__button"
        flattened
        label={`Открыть все результаты поиска: ${searchOrderStore.orderDataStore.total}`}
        onClick={() => history.push(AppRoute.SEARCH_ORDERS_PAGE_ROUTE)}
        theme="filled"
      />
    </div>
  );
});

export default OrderSearchResultPopup;
