import type { IAgreementApi } from 'src/interfaces/agreement.interface';
import type { IDocumentApi } from 'src/interfaces/document.interface';

export const AGREEMENT_API_MOCK: IAgreementApi = {
  id: 'agreementMockId',
  no: 'O-2PPQT-1',
  status: 'draft',
  payment_status: 'unpaid',
  total_price: 15600,
  created_at: '24.05.2021',
  discount: 0.15,
  items: {
    package: {
      id: 'package',
      sku: 'P-HT1EU',
      name: 'Ритуальная услуга «Захоронение», вариант «Бизнес»',
      price: 155000,
      calculated_price: 139500,
      items: [
        {
          id: '1',
          sku: 'S-ANV66',
          name: 'Предоставление постамента для гроба при проведении ритуала захоронения',
          attributes: {
            finish_date: '2021-05-24T06:30:00.000Z',
          },
          amount: 1,
          unit: 'штука',
        },

        {
          id: '2',
          sku: 'S-5QAJO',
          name: 'Предоставление чаш с песком и лопатки для проведения ритуала захоронения',
          attributes: {
            finish_date: '2021-05-25T06:30:00.000Z',
          },
          amount: 2,
          unit: 'куб.м.',
          added: true,
        },

        {
          id: '3',
          sku: 'S-EJX54',
          name: 'Оформление могилы еловыми ветками',
          attributes: {
            finish_date: '2021-05-24T06:30:00.000Z',
          },
          amount: 100,
          unit: 'кв.дм.',
        },

        {
          id: '4',
          sku: 'S-GNDBJ',
          name: 'Комплекс работ по захоронению гроба «Стандарт плюс»',
          attributes: {
            finish_date: '2021-05-24T06:30:00.000Z',
          },
          amount: 1,
          unit: 'кв.м.',
        },

        {
          id: '5',
          sku: 'S-GF7R1',
          name: 'Ограда металлическая, тип "Стандартная", вид "Дуновение"',
          attributes: {
            finish_date: '2021-07-18T06:30:00.000Z',
          },
          amount: 4,
          unit: 'час',
        },

        {
          id: '6',
          sku: 'S-1YSTL',
          name: 'Гравировка надписи, шрифт "Стандарт", высота знака до 5 см',
          attributes: {
            finish_date: '2021-07-14T06:30:00.000Z',
          },
          amount: 65,
          unit: 'кг.',
        },

        {
          id: '7',
          sku: 'S-3OCJD',
          name: 'Предоставление шатра размером 3 х 6 метра для проведения ритуала захоронения',
          attributes: {
            finish_date: '2021-05-24T06:30:00.000Z',
          },
          amount: 1,
          unit: 'метр',
        },
      ],
    },

    additional_items: [
      {
        id: 'additional_items1',
        sku: 'G-6CIVF',
        name: 'Венок "Волна" 1,1 м',
        attributes: {
          finish_date: '2022-08-12T02:00:00.000Z',
          address: 'На Попова 14',
        },
        price: 300,
        amount: 1,
        unit: 'штука',
        calculated_price: 270,
        added: true,
      },

      {
        id: 'additional_items2',
        sku: 'G-1DDLP',
        name: 'Венок "Волна" 1,1 м',
        attributes: {
          finish_date: '2022-08-12T02:00:00.000Z',
          address: 'На Попова 14',
        },
        price: 3850,
        amount: 1,
        unit: 'штука',
        calculated_price: 3850,
      },

      {
        id: 'additional_items3',
        sku: 'G-HMZPG',
        name: 'Венок "Волна" 1,1 м',
        attributes: {
          finish_date: '2022-08-12T02:00:00.000Z',
          address: 'На Попова 14',
        },
        price: 3450,
        amount: 1,
        unit: 'штука',
        calculated_price: 2932,
        deleted: true,
      },
    ],
  },
};

export const AGREEMENT_DOCUMENTS_API_MOCK: IDocumentApi[] = [
  {
    name: 'Удостоверение_ветерана.pdf',
    type: 'Удостоверение_ветерана',
    url: 'https://someaddr/documents/O-D25BT/917381c4c61c9230932e7dd30edad64e.png',
    created_at: '2021-06-21T00:00:00.000Z',
  },
  {
    name: 'Cправка.pdf',
    type: 'Cправка',
    url: 'https://someaddr/documents/O-D25BT/917381c4c61c9230932e7dd30edad64e.png',
    created_at: '2021-06-21T00:00:00.000Z',
  },
];
