import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';

import Loader from 'src/components/common/loader';
import { ReactComponent as UploadIcon } from 'src/assets/icons/upload.svg';

import type { FileUploaderProps } from './file-uploader.props';
import { ACCEPTED_FILE_TYPES, FileLimit, Regex } from 'src/constants';
import { convertMBToBYTE } from 'src/utils';

import './file-uploader.scss';

const FileUploader = forwardRef<HTMLInputElement, FileUploaderProps>(
  (
    {
      className,
      files = [],
      handleFilesUpload,
      handleError,
      isFormLoading,
      maxFiles = null,
      maxSize,
    }: FileUploaderProps,
    ref
  ) => {
    const [loading, setLoading] = React.useState(false);
    const isInputDisabled = isFormLoading || loading;
    const isUploadAllowed = maxFiles === null || files.length < maxFiles;

    const onFileChange = (evt: any, fileList?: FileList) => {
      setLoading(true);
      const newUploadingFiles = fileList || evt.target?.files || [];
      const stateFiles = [...files];
      const filesCount =
        !maxFiles || newUploadingFiles.length + files.length < maxFiles
          ? newUploadingFiles.length
          : maxFiles - files.length;

      for (let i = 0; i < filesCount; i++) {
        const file = newUploadingFiles[i];
        if (file === undefined) {
          setLoading(false);
          return;
        }
        if (!file.type.match(Regex.FILE_MIME_UPLOAD_TYPE)) {
          handleError(FileLimit.TYPE);
          setLoading(false);
          return;
        }
        if (file.size > convertMBToBYTE(maxSize)) {
          handleError(FileLimit.SIZE);
          setLoading(false);
          return;
        }

        const fileName = file.name;
        const { type } = file;
        const newFile = {
          id: uuidv4(),
          file,
          fileName,
          document: null,
          type,
        };
        stateFiles.push(newFile);
        if (i === filesCount - 1) {
          handleFilesUpload(stateFiles);
          setLoading(false);
        }
      }
    };

    const onDragOver = (evt: React.DragEvent) => evt.preventDefault();

    const onDrop = (evt: React.DragEvent) => {
      evt.preventDefault();
      if (isUploadAllowed) {
        onFileChange(evt, evt.dataTransfer.files);
      }
    };

    return (
      <div className={classNames('file-uploader', className)}>
        <label
          htmlFor="fileUploaderInput"
          className={classNames(
            'file-uploader__browse',
            (!isUploadAllowed || isInputDisabled) && 'file-uploader__browse_disabled'
          )}
          onDragOver={onDragOver}
          onDrop={onDrop}
        >
          <UploadIcon className="file-uploader__browse-icon" />
          <span className="file-uploader__browse-text">
            Перетащите файлы сюда или кликните для выбора с устройства
          </span>
          <input
            id="fileUploaderInput"
            className="file-uploader__browse-input"
            disabled={!isUploadAllowed || isInputDisabled}
            type="file"
            ref={ref}
            multiple
            accept={ACCEPTED_FILE_TYPES}
            onChange={onFileChange}
            onClick={(evt) => evt.stopPropagation()}
          />
        </label>
        {(loading || isFormLoading) && <Loader className="loader_position_overlay " />}
      </div>
    );
  }
);

export default FileUploader;
