import React, { SyntheticEvent } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';

import ButtonIcon from 'src/components/ui/button-icon';
import DocumentIcon from 'src/components/common/document-icon';
import Select from 'src/components/ui/select';
import { ReactComponent as IconDelete } from 'src/assets/icons/delete.svg';

import constantsStore from 'src/stores/constants-store';

import type { UploadedFileProps } from './uploaded-file.props';

import './uploaded-file.scss';

const UploadedFile = observer(
  ({
    className,
    file,
    files,
    handleFilesUpload,
    location = 'page',
    isDisabled,
  }: UploadedFileProps) => {
    const removeFile = (evt: SyntheticEvent) => {
      evt.preventDefault();

      const tempFiles = [...files];
      const fileIdx = tempFiles.findIndex((tempFile) => tempFile.id === file.id);
      tempFiles.splice(fileIdx, 1);
      handleFilesUpload(tempFiles);
    };

    const handleDocumentSelect = (documentId: string) => {
      file.document = documentId;

      const tempFiles = [...files];
      const fileIdx = tempFiles.findIndex((tempFile) => tempFile.id === file.id);
      tempFiles.splice(fileIdx, 1, file);
      handleFilesUpload(tempFiles);
    };

    return (
      <div className={classNames('uploaded-file', `uploaded-file_location_${location}`, className)}>
        <DocumentIcon className="uploaded-file__icon" mimeType={file.type} />
        <p className="uploaded-file__name">{file.fileName}</p>
        <Select
          className="uploaded-file__select"
          label="Выберите тип документа"
          isDisabled={isDisabled}
          flattened={location === 'popup'}
          items={constantsStore.documentTypesSelectList}
          onChange={handleDocumentSelect}
          value={file.document || ''}
        />
        <ButtonIcon
          className="uploaded-file__delete-btn"
          ariaLabel="Delete document"
          color="theme"
          isDisabled={isDisabled}
          icon={<IconDelete />}
          onClick={removeFile}
        />
      </div>
    );
  }
);

export default UploadedFile;
