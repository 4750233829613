import React from 'react';

import ButtonIcon from 'src/components/ui/button-icon';
import { ReactComponent as CrossIcon } from 'src/assets/icons/cross.svg';

import type { AgreementHeaderProps } from './agreement-header.props';

import './agreement-header.scss';

function AgreementHeader({ title, onCloseButtonClick, children }: AgreementHeaderProps) {
  return (
    <header className="agreement-header">
      <h1 className="agreement-header__title">{title}</h1>
      {children}
      {onCloseButtonClick && (
        <ButtonIcon
          className="agreement-header__close-button"
          ariaLabel="Закрыть"
          icon={<CrossIcon />}
          onClick={onCloseButtonClick}
        />
      )}
    </header>
  );
}

export default AgreementHeader;
