import React from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';

import Button from 'src/components/ui/button';

import popupStore, { POPUP_NAMES } from 'src/stores/popups/popup-store';

import useOnClickOutside from 'src/hooks/use-on-click-outside';
import useKeyUp from 'src/hooks/use-key-up';
import { Key } from 'src/constants';

import '../popup.scss';

const INFO_POPUP = POPUP_NAMES.INFO_POPUP;

export const InfoPopup = observer(() => {
  const popupRef = React.useRef<HTMLDivElement | null>(null);

  const { isVisible, title, text, onClose } = popupStore.getPopupState(INFO_POPUP);

  const closePopup = () => {
    popupStore.hidePopup(INFO_POPUP);
    onClose?.();
  };

  useOnClickOutside(popupRef, closePopup, !isVisible);
  useKeyUp(Key.ESCAPE, closePopup, !isVisible);

  return (
    <div className={classNames('popup popup_info', isVisible && 'popup_visible')} ref={popupRef}>
      <div className="popup__title">{title}</div>
      <div className="popup__text">{text}</div>
      <div className="popup__actions">
        <Button onClick={closePopup} label="Ок" theme="filled" />
      </div>
    </div>
  );
});

export default InfoPopup;
