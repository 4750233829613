import React from 'react';
import { Link } from 'react-router-dom';

import { getFullName } from 'src/utils/get-full-name';
import type { IPerson } from 'src/interfaces/person.interface';
import type { IOrderListProps } from './order-list.props';

import './order-list.scss';

const orderCfg = [
  { label: 'Заказ', field: 'no' },
  { label: 'Заказчик', field: 'client', fn: getFullName },
  { label: 'Усопший', field: 'deceased', fn: getFullName },
  { label: 'Оформлен', field: 'createdAt' },
];

function OrderList({ orders }: IOrderListProps) {
  return (
    <ul className="order-list">
      {orders.map((order) => (
        <li key={order.id} className="order-list__item">
          <Link
            to={`/order/${order.id}`}
            target="_blank"
            rel="noopener noreferrer"
            className="order-list__link"
          >
            {orderCfg.map(({ label, field, fn }) => (
              <div key={field} className="order-list__cell">
                <span className="order-list__label">{label}</span>
                <span className="order-list__value">
                  {fn ? fn(order[field] as IPerson) : order[field]}
                </span>
              </div>
            ))}
          </Link>
        </li>
      ))}
    </ul>
  );
}

export default OrderList;
