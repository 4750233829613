import React, { FormEvent } from 'react';
import { observer } from 'mobx-react-lite';
import { v4 as uuidv4 } from 'uuid';

import Button from 'src/components/ui/button';
import ButtonIcon from 'src/components/ui/button-icon';
import Input from 'src/components/ui/input';
import UploadableAttachments from '../uploadable-attachments';
import { ReactComponent as PaperclipIcon } from 'src/assets/icons/paperclip.svg';

import chatStore from 'src/stores/chat-store';
import globalAlertStore from 'src/stores/global-alert-store';

import {
  ACCEPTED_FILE_TYPES,
  ErrorText,
  MAX_COUNT_FILES,
  MAX_FILE_SIZE_MB,
  Regex,
} from 'src/constants';
import { convertMBToBYTE, getAlertOptions, isDocumentFile } from 'src/utils';
import type { IUploadableAttachment } from 'src/interfaces/uploadable-attachment.interface';

import './comment-input.scss';

const CommentInput = observer(() => {
  const inputFileRef = React.useRef<HTMLInputElement | null>(null);

  const [message, setMessage] = React.useState('');
  const [attachments, setAttachments] = React.useState<IUploadableAttachment[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const isUploadDisable = attachments.length >= MAX_COUNT_FILES || isLoading;
  const isSubmitDisable = isLoading || (!message.trim() && attachments.length === 0);

  const handleSubmit = (evt: FormEvent<HTMLFormElement>) => evt.preventDefault();

  const clearInputFileRef = () => {
    if (inputFileRef?.current) {
      inputFileRef.current.value = '';
    }
  };

  const handleInputFileChange = () => {
    setIsLoading(true);

    const newUploadingFiles = inputFileRef.current?.files || [];
    const stateAttachments = [...attachments];
    const filesCount =
      newUploadingFiles.length + attachments.length < MAX_COUNT_FILES
        ? newUploadingFiles.length
        : MAX_COUNT_FILES - attachments.length;

    for (let i = 0; i < filesCount; i++) {
      const file = newUploadingFiles[i];

      if (file === undefined) {
        setIsLoading(false);
        return;
      }

      if (!file.type.match(Regex.FILE_MIME_UPLOAD_TYPE)) {
        globalAlertStore.addAlert(getAlertOptions(ErrorText.FILE_TYPE));
        clearInputFileRef();
        setIsLoading(false);
        return;
      }

      if (file.size > convertMBToBYTE(MAX_FILE_SIZE_MB)) {
        globalAlertStore.addAlert(getAlertOptions(ErrorText.FILE_SIZE));
        clearInputFileRef();
        setIsLoading(false);
        return;
      }

      const attachment: IUploadableAttachment = {
        id: uuidv4(),
        file,
      };

      if (!isDocumentFile(file.type)) {
        attachment.imageURL = URL.createObjectURL(file);
      }

      stateAttachments.push(attachment);

      if (i === filesCount - 1) {
        setAttachments(stateAttachments);
      }
    }

    clearInputFileRef();
    setIsLoading(false);
  };

  const handleAddFileButtonClick = () => {
    inputFileRef.current?.click();
  };

  const handleSubmitButton = async () => {
    await chatStore.sendComments(
      message,
      attachments.map((attachment) => attachment.file)
    );

    attachments.forEach(({ imageURL }) => {
      if (imageURL) {
        URL.revokeObjectURL(imageURL);
      }
    });

    setMessage('');
    setAttachments([]);
  };

  const handleRemoveButtonClick = (attachmentId: string, imageURL?: string) => {
    setAttachments((prev) => prev.filter(({ id }) => id !== attachmentId));

    if (imageURL) {
      URL.revokeObjectURL(imageURL);
    }
  };

  return (
    <form className="comment-input" onSubmit={handleSubmit} autoComplete="off">
      <input
        accept={ACCEPTED_FILE_TYPES}
        hidden
        multiple
        onChange={handleInputFileChange}
        ref={inputFileRef}
        type="file"
      />

      <Input
        icon={
          <ButtonIcon
            ariaLabel="Add files"
            icon={<PaperclipIcon className="comment-input__add-icon" />}
            isDisabled={isUploadDisable}
            onClick={handleAddFileButtonClick}
            type="button"
          />
        }
        id="chat"
        onChange={setMessage}
        placeholder="Ваш комментарий к заказу"
        value={message}
      />

      <Button
        isDisabled={isSubmitDisable}
        label="Отправить"
        onClick={handleSubmitButton}
        theme="outlined"
      />

      {attachments.length > 0 ? (
        <UploadableAttachments
          attachments={attachments}
          handleRemoveButtonClick={handleRemoveButtonClick}
        />
      ) : null}
    </form>
  );
});

export default CommentInput;
