import { action, computed, makeObservable, observable } from 'mobx';

import { ApiResponseStatus } from 'src/constants';
import type { IAgreementApi, IAgreement } from 'src/interfaces/agreement.interface';
import type { IDocument, IDocumentApi } from 'src/interfaces/document.interface';
import { mapApiToAgreement, mapApiToDocumentWithDate } from 'src/adapters';
import {
  AGREEMENT_API_MOCK,
  AGREEMENT_DOCUMENTS_API_MOCK,
} from 'src/stores/mocks/agreement-api-mock';

export class AgreementStore {
  init(id: string) {
    this.loadAgreement(id);
    this.loadAgreementDocuments(id);
  }

  agreement: IAgreement | null = null;
  documents: IDocument[] = [];
  isLoading = false;
  isDocumentsLoading = false;

  constructor() {
    makeObservable(this, {
      agreement: observable,
      agreementId: computed,
      documents: observable,
      isDocumentsLoading: observable,
      isLoading: observable,
      setAgreement: action,
      setDocuments: action,
      setIsDocumentsLoading: action,
      setIsLoading: action,
    });
  }

  get agreementId() {
    return this.agreement?.id;
  }

  get noSubtitleDocuments() {
    return this.documents.map((item) => {
      const editedItem = { ...item };
      delete editedItem.subtitle;
      return editedItem;
    });
  }

  setAgreement(agreement: IAgreement | null) {
    this.agreement = agreement;
  }

  setDocuments(documents: IDocument[]) {
    this.documents = documents;
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  setIsDocumentsLoading(isLoading: boolean) {
    this.isDocumentsLoading = isLoading;
  }

  async loadAgreement(id: string) {
    this.setIsLoading(true);
    // FIXME: remove mock
    try {
      const result: { status: number; data: IAgreementApi } = await new Promise((res) => {
        setTimeout(() => {
          res({ status: ApiResponseStatus.SUCCESS, data: AGREEMENT_API_MOCK });
        }, 400);
      });

      if (result.status === ApiResponseStatus.SUCCESS) {
        this.setAgreement(mapApiToAgreement(result.data));
      }
    } catch (e) {
      console.log(e);
    }
    this.setIsLoading(false);
  }

  async loadAgreementDocuments(id: string) {
    this.setIsDocumentsLoading(true);
    // FIXME: remove mock
    try {
      const result: { status: number; data: any } = await new Promise((res) => {
        setTimeout(() => {
          res({ status: ApiResponseStatus.SUCCESS, data: AGREEMENT_DOCUMENTS_API_MOCK });
        }, 400);
      });

      if (result.status === ApiResponseStatus.SUCCESS) {
        const mappedData = result.data.map((it: IDocumentApi) => mapApiToDocumentWithDate(it));
        this.setDocuments(mappedData);
      }
    } catch (e) {
      console.log(e);
    }

    this.setIsDocumentsLoading(false);
  }
}

export default new AgreementStore();
