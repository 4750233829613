import React from 'react';
import { observer } from 'mobx-react-lite';

import ImageViewer from 'src/components/common/popups/image-viewer';
import ConfirmPopup from 'src/components/common/popups/confirm-popup';
import InfoPopup from 'src/components/common/popups/info-popup';
import UploadPopup from 'src/components/common/popups/upload-popup';

const AllPopups = observer(() => {
  return (
    <>
      <ImageViewer />
      <ConfirmPopup />
      <InfoPopup />
      <UploadPopup />
    </>
  );
});

export default AllPopups;
