import React from 'react';
import { observer } from 'mobx-react-lite';

import constantsStore from 'src/stores/constants-store';

import './payment-status-badge.scss';

const PaymentStatusBadge = observer(({ status }: { status?: string }) => {
  if (!status) {
    return null;
  }

  return (
    <div className={`payment-status-badge payment-status-badge_${status}`}>
      {constantsStore.getPaymentStatusLabel(status)}
    </div>
  );
});

export default PaymentStatusBadge;
