import React from 'react';
import { observer } from 'mobx-react-lite';

import Input from 'src/components/ui/input';
import { ReactComponent as CrossIcon } from 'src/assets/icons/cross.svg';
import { ReactComponent as SearchIcon } from 'src/assets/icons/search.svg';

import searchOrderStore from 'src/stores/search-order-store';

const OrderSearchInput = observer(({ autoload = false }: { autoload?: boolean }) => {
  React.useEffect(() => {
    searchOrderStore.setIsOrderAutoload(autoload);
  }, [autoload]);

  const renderIcon = () => {
    if (searchOrderStore.searchString) {
      return (
        <CrossIcon onClick={searchOrderStore.clearSearchString} className="input__clear-icon" />
      );
    }
    return <SearchIcon />;
  };

  return (
    <Input
      icon={renderIcon()}
      id="order-search-input"
      onChange={searchOrderStore.setSearchString}
      placeholder="Введите номер заказа или ФИО Усопшего/Заказчика"
      type="search"
      value={searchOrderStore.searchString}
    />
  );
});

export default OrderSearchInput;
