import React from 'react';
import { observer } from 'mobx-react-lite';

import OrderCard from '../order-card';
import { ReactComponent as FemaleIcon } from 'src/assets/icons/female.svg';
import { ReactComponent as MaleIcon } from 'src/assets/icons/male.svg';
import { ReactComponent as NoteIcon } from 'src/assets/icons/note.svg';
import { ReactComponent as SimpleHouseIcon } from 'src/assets/icons/simple-house.svg';

import orderStore from 'src/stores/order-store';
import preorderStore from 'src/stores/preorder-store';

import type { IOrderCardRow } from '../order-card';
import type { ClientCardProps } from './client-card.props';
import type { IIdentity } from 'src/interfaces/identity';
import { getFormattedDate, getFormattedPhone, getFullName } from 'src/utils';
import { Sex } from 'src/constants';

const ClientCard = observer(({ isPreOrder }: ClientCardProps) => {
  const client = isPreOrder ? preorderStore.preorder?.client : orderStore.order?.client;

  if (!client) {
    return null;
  }

  let data: IOrderCardRow[] = [];

  if (isPreOrder) {
    const { dob, email, firstName, lastName, patronymic, phone, sex } = client;
    data = [
      {
        icon: sex === Sex.FEMALE ? <FemaleIcon /> : <MaleIcon />,
        texts: [
          { text: `${getFullName({ firstName, lastName, patronymic })}${dob ? `, ${dob}` : ''}` },
          { isAdditionalText: true, text: getContacts(phone, email ?? '') },
        ],
      },
    ];
  } else {
    const {
      address = '',
      dob,
      email,
      firstName,
      identity,
      lastName,
      patronymic,
      phone,
      sex,
    } = client;
    data = [
      {
        icon: sex === Sex.FEMALE ? <FemaleIcon /> : <MaleIcon />,
        texts: [
          { text: `${getFullName({ firstName, lastName, patronymic })}${dob ? `, ${dob}` : ''}` },
          { isAdditionalText: true, text: getContacts(phone, email ?? '') },
        ],
      },

      {
        icon: <NoteIcon />,
        texts: [
          { text: identity?.type ?? '' },
          {
            isAdditionalText: true,
            text: getDocumentDescription(identity as IIdentity),
          },
        ],
      },
    ];

    if (address) {
      data.splice(1, 0, {
        icon: <SimpleHouseIcon />,
        texts: [{ text: address }],
      });
    }
  }

  return <OrderCard data={data} title="Заказчик" />;
});

const getContacts = (phone: string, email: string) => {
  const str = `${getFormattedPhone(phone)}`;

  if (!!email) {
    return `${str} / ${email}`;
  }

  return str;
};

const getDocumentDescription = ({
  departmentCode,
  issuedBy,
  issueDate,
  number,
  series,
}: IIdentity) => {
  let str = series ?? '';
  str += !!number ? ` ${number}` : '';
  str += !!issueDate ? ` от ${getFormattedDate(issueDate)}` : '';
  str += !!issuedBy ? `, выдан ${issuedBy}` : '';
  str += !!departmentCode ? `, код ${departmentCode}` : '';
  return str;
};

export default ClientCard;
