import React from 'react';
import classNames from 'classnames';

import { ReactComponent as ArrowIcon } from 'src/assets/icons/arrow.svg';

import searchOrderStore from 'src/stores/search-order-store';

import { getFullName, getFormattedPhone } from 'src/utils';

import './orders-table.scss';

const headCfg = ['Заказ №', 'Заказчик', 'Усопший', 'Добавлено'];

function OrdersTable() {
  const tBodyRef = React.useRef<HTMLTableSectionElement | null>(null);
  const observerRef = React.useRef<IntersectionObserver | null>(null);
  const targetRef = React.useRef(null);

  const orders = searchOrderStore.orders;

  React.useEffect(() => {
    observerRef.current = new IntersectionObserver(searchOrderStore.searchResultLoadOrders, {
      root: tBodyRef.current,
      rootMargin: '0px',
      threshold: 0,
    });

    if (targetRef.current) {
      observerRef.current.observe(targetRef.current);
    }

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [orders]);

  return (
    <table className="orders-table">
      <thead className="orders-table__head">
        <tr className="orders-table__head-row">
          {headCfg.map((label) => (
            <th key={label} className="orders-table__head-cell">
              {label}
            </th>
          ))}
        </tr>
      </thead>
      <tbody ref={tBodyRef} className="orders-table__body scrollbar">
        {orders.map(({ id, no, client, deceased, createdAt }, idx) => (
          <tr
            key={id}
            className={classNames(
              'orders-table__body-row',
              orders.length - 1 === idx && 'orders-table__body-row_last'
            )}
            ref={orders.length - 1 === idx ? targetRef : null}
            onClick={() => window.open(`/order/${id}`, '_blank', 'noopener,noreferrer')}
          >
            <td className="orders-table__body-cell">{no}</td>
            <td className="orders-table__body-cell">
              {getFullName(client)}
              <span className="orders-table__body-cell_light">
                {getFormattedPhone(client.phone)}
              </span>
            </td>
            <td className="orders-table__body-cell">
              {getFullName(deceased)}
              <span className="orders-table__body-cell_light">
                {`${deceased.dob || ''} - ${deceased.dod || ''}`}
              </span>
            </td>
            <td className="orders-table__body-cell orders-table__body-cell_icon">
              {createdAt}
              <ArrowIcon className="orders-table__icon" />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default OrdersTable;
