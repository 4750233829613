import { makeAutoObservable } from 'mobx';
import { doGet } from 'src/api';
import { ApiResponseStatus, RESPONSE_HEADERS_TOTAL_COUNT_KEY } from 'src/constants';

interface IPagingProps {
  limit?: number;
  offset?: number;
}

interface IFilter {
  [propertyName: string]: any;
}

interface ISort {
  [propertyName: string]: string;
}

interface IListeners {
  [propertyName: string]: any;
}

interface IData<T> {
  data?: T[];
  url?: string;
  filters?: IFilter | null;
  sort?: ISort | null;
  pagingType?: string;
  pagingProps?: IPagingProps | null;
  listeners?: IListeners | null;
  loadOnCreate?: boolean;
}

class DataStore<T> {
  data: T[] = [];
  url = '';
  filters: IFilter | null = null;
  sort: ISort | null = null;
  pagingProps: IPagingProps | null = null;
  pagingType = '';
  listeners: IListeners | null = null;
  currentPage = 1;
  total = 0;
  isLoading = false;

  constructor({
    data = [],
    url = '',
    filters = {},
    sort = null,
    pagingType = '',
    listeners = null,
    pagingProps = null,
    loadOnCreate = false,
  }: IData<T>) {
    this.data = data;
    this.url = url;
    this.filters = filters;
    this.sort = sort;
    this.pagingType = pagingType;
    this.listeners = listeners;

    if (!!pagingProps) {
      this.pagingProps = {
        limit: pagingProps.limit || 5,
        offset: pagingProps.offset || 0,
      };
    }

    makeAutoObservable(this);

    if (loadOnCreate) {
      this.load();
    }
  }

  get getData() {
    return this.data;
  }

  get getCurrentPage() {
    return this.currentPage;
  }

  setData(data: T[]) {
    this.data = data || [];
  }

  setUrl(url: string) {
    this.url = url;
  }

  setSort(sort: ISort, skipLoad = false) {
    this.sort = sort;

    if (!skipLoad) {
      this.load();
    }
  }

  setPagingType(type: string) {
    this.pagingType = type;
  }

  addFilter(filterName: string, value: any, skipLoad = false) {
    const filters = Object.assign({}, this.filters, { [filterName]: value });
    this.setFilters(filters, skipLoad);
  }

  setFilters(filters: IFilter, skipLoad = false) {
    this.filters = filters;

    if (!skipLoad) {
      this.setPagingType('');
      this.loadPage(1);
    }
  }

  setTotal(total: number) {
    this.total = total;
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  loadNextPage() {
    this.setPagingType('endless');
    this.loadPage(this.currentPage + 1);
  }

  loadPage(page: number) {
    this.currentPage = page;

    if (this.pagingProps) {
      this.pagingProps = {
        ...this.pagingProps,
        offset: (page - 1) * (this.pagingProps.limit || 0),
      };
    }

    this.load();
  }

  async load() {
    if (!this.url) {
      return;
    }

    let data = [];

    this.setIsLoading(true);

    const params = {
      ...this.filters,
      ...this.pagingProps,
      sort: this.sort,
    };

    try {
      const result = await doGet(this.url, { params });

      if (result.status === ApiResponseStatus.SUCCESS) {
        data = result.data;

        const total = result?.headers?.[RESPONSE_HEADERS_TOTAL_COUNT_KEY];
        if (total) {
          this.setTotal(parseInt(total, 10));
        }

        data = this.tryExecuteListener('onDataLoad', data);

        if (this.pagingType === 'endless') {
          this.setData([...this.data, ...data]);
        } else {
          this.setData(data);
        }
      }
    } catch (e) {
      console.log('e', e);
    }

    this.setIsLoading(false);
  }

  tryExecuteListener(listenerName: string, args: any) {
    const listener = (this.listeners || {})[listenerName];

    return listener ? listener(this, args) : args;
  }
}

export default DataStore;
