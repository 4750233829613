import React, { ReactElement } from 'react';
import classNames from 'classnames';

import { ReactComponent as CloseIcon } from 'src/assets/icons/cancel.svg';
import { ReactComponent as ErrorIcon } from 'src/assets/icons/error.svg';
import { ReactComponent as InfoIcon } from 'src/assets/icons/info.svg';
import { ReactComponent as WarningIcon } from 'src/assets/icons/warning.svg';

import type { AlertType, IAlertProps } from './alert.props';

import './alert.scss';

export function Alert({ desc, onClose, timeout, title, type, withIcon }: IAlertProps) {
  const timeoutRef = React.useRef<NodeJS.Timeout | null>(null);

  React.useEffect(() => {
    if (timeout && !timeoutRef.current) {
      timeoutRef.current = setTimeout(() => onClose(), timeout);
    }
  }, [timeout, onClose]);

  React.useEffect(
    () => () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    },
    []
  );

  const classes = classNames('alert', `alert_${type}`, {
    alert_icon: withIcon,
  });

  return (
    <div className={classes}>
      {withIcon && getTypeDependedIcon(type)}

      <div className="alert__title">{title}</div>

      <div className="alert__desc">{desc}</div>

      <CloseIcon className="alert__close-icon" onClick={onClose} />
    </div>
  );
}

function getTypeDependedIcon(type: AlertType) {
  const classesIcon = classNames('alert__type-icon', `alert__type-icon_${type}`);

  const cfg: { [propertyName: string]: ReactElement } = {
    error: <ErrorIcon className={classesIcon} />,
    info: <InfoIcon className={classesIcon} />,
    warning: <WarningIcon className={classesIcon} />,
  };

  return cfg[type];
}
