import React from 'react';
import classNames from 'classnames';

import type { ButtonProps } from './button.props';

import './button.scss';

function Button({
  ariaLabel,
  className,
  flattened,
  icon,
  isDisabled,
  label,
  onClick,
  theme,
  type,
}: ButtonProps) {
  const classes = classNames('button', !!theme && `button_${theme}`, className, {
    button_disabled: isDisabled,
    button_flattened: flattened,
  });

  return (
    <button
      aria-label={ariaLabel}
      className={classes}
      disabled={isDisabled}
      onClick={onClick}
      type={type}
    >
      {!!icon ? <span className="button__icon">{icon}</span> : null}

      <span className="button__label">{label}</span>
    </button>
  );
}

export default Button;
